import fastdom from 'fastdom';

export function getCoordinates(el, next) {
  let coord = {};

  fastdom.measure(() => {
    try {
      coord = el.getBoundingClientRect();
    } catch (err) {
      next(err);
      return;
    }

    next(null, coord);
  });
}

export function getMultipleCoordinates(els, next) {
  const coordinates = new Array(els.length).fill(null);

  els.forEach((el, i) => {
    getCoordinates(el, (err, coord) => {
      if (err) {
        next(err);
        return;
      }

      coordinates[i] = coord;
      if (coordinates.every(c => c !== null)) next(null, coordinates);
    });
  });
}

export function moveTo(el, target, next) {
  getMultipleCoordinates([el, target], (err, [elCoord, targetCoord]) => {
    if (err) {
      next(err);
      return;
    }

    let left = targetCoord.left - elCoord.width / 2;
    /* if the left side */
    if (left < 0) left = 0;
    /* or the right side are off the viewport */
    if (left + elCoord.width >= window.innerWidth) {
      left -= left + elCoord.width - window.innerWidth + 5;
    }

    const top = window.pageYOffset + targetCoord.bottom;

    fastdom.mutate(() => {
      try {
        /* eslint-disable no-param-reassign */
        el.style.top = `${top}px`;
        el.style.left = `${left}px`;
      } catch (error) {
        next(error);
        return;
      }

      next(null);
    });
  });
}
