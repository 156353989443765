import units from '../../utils/units'
import { BaseComponent, BElement } from '../../utils/base-component'
import './index.scss'

export class RainAmount extends BaseComponent {
  static cssClass = 'rain-amount'

  value?: number
  text!: BElement

  init() {
    const value = parseFloat(this.root.dataset.value!)
    this.value = Number.isNaN(value) ? undefined : value

    this.text = this.element('value')!

    this.render()
    units.onChange(() => this.render())
  }

  render() {
    if (!this.text) return

    const value = this.value
      ? units.formatMm(this.value, { precisionLimit: 2 })
      : '—'
    this.text.setHTML(value)
  }
}
