import './index.scss';
import SwellIcon from './swell-icon';

export { SwellIcon };

const mapping = [];

export function swellIconFor(el, units) {
  let entry = mapping.find(m => m[0] === el);
  if (!entry) {
    entry = [el, new SwellIcon({ el, units })]
    mapping.push(entry);
  }
  return entry[1];
}

export default function(container, units) {
  return [...container.querySelectorAll('.swell-icon')].map(el =>
    swellIconFor(el, units)
  );
}
