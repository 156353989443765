function toFmt(val) {
  if (val >= 0 && val <= 9) {
    return `0${val}`;
  }
  return val;
}

export const to12HrFmt = localDate => {
  let hours = localDate.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  let timeStr = `${hours}:${toFmt(localDate.getMinutes())}`;
  timeStr += `:${toFmt(localDate.getSeconds())}`;
  timeStr += ` ${ampm}`;
  return timeStr;
};

export const timestampsDiff = (first, second, withSeconds = false) => {
  const delta = second - first;
  let deltaStr = '';

  if (Math.floor(delta / 3600) > 0) {
    deltaStr = `${Math.floor(delta / 3600)}hr `;
  }

  if (Math.floor((delta % 3600) / 60) > 0) {
    deltaStr += `${Math.floor((delta % 3600) / 60)}min `;
  }

  if (withSeconds && toFmt(delta % 60)) {
    deltaStr += `${toFmt(Math.floor(delta % 60))}s`;
  }

  return deltaStr;
};
