import fastdom from 'fastdom';

/**
 * Writes new classes to the element based on provided predicate functions.
 * @async
 * @param {function} predicateRight - a predicate to define what class it needs to append to the el
 * @param {function} predicateLeft - a predicate to define what class it needs to append to the el
 * @param {HTMLElement} el - Any element in DOM
 * @param {function} [() => 1] next - A callback function to call after the function is finished.
 * @returns {void}
 */
function toggleClasses(predicateRight, predicateLeft, el, next = () => 1) {
  fastdom.mutate(() => {
    if (predicateRight()) el.classList.add('has-scroll-space-on-right');
    else el.classList.remove('has-scroll-space-on-right');

    if (predicateLeft()) el.classList.add('has-scroll-space-on-left');
    else el.classList.remove('has-scroll-space-on-left');

    next();
  });
}

export default toggleClasses;
