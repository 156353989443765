import NextLowOrHighTideMarker from './next-low-or-high-tide-marker';
import MeasurementMarker from './measurement-marker';
import CurrentTideMarker from './current-tide-marker';
import TideState from './tide-state';
import Countdowns from './countdowns';

class LiveTide {
  constructor({ css, el, tides, clock, units }) {
    this.css = css;
    this.wave = el.querySelector(`.${css.wave}`);
    this.markersEl = el.querySelector(`.${css.markers}`);
    this.tides = tides;
    this.clock = clock;

    const currentTideEl = this.markersEl.querySelector(`.${css.current}`);
    this.currentMarker = new CurrentTideMarker({
      css: css.currentTide,
      el: currentTideEl,
      units,
    });

    const highMarkerEl = this.markersEl.querySelector(`.${css.highMarker}`);
    this.highMarker = new NextLowOrHighTideMarker({
      css,
      el: highMarkerEl,
      units,
    });

    const lowMarkerEl = this.markersEl.querySelector(`.${css.lowMarker}`);
    this.lowMarker = new NextLowOrHighTideMarker({
      css,
      el: lowMarkerEl,
      units,
    });

    const midMarkerEl = this.markersEl.querySelector(`.${css.midMarker}`);
    this.midMarker = new MeasurementMarker({ css, el: midMarkerEl });

    const tideStateEl = el.querySelector(`.${css.tideState}`);
    this.tideState = new TideState({ css, el: tideStateEl });

    this.countdowns = new Countdowns({ css, el, clock });
  }

  addIndentToMarkersIfNeeded() {
    this.markersEl.classList.remove(this.css.markersTop);
    this.markersEl.classList.remove(this.css.markersBottom);

    if (this.currentMarker.isCloseToTopBorder()) {
      this.markersEl.classList.add(this.css.markersTop);
    } else if (this.currentMarker.isCloseToBottomBorder()) {
      this.markersEl.classList.add(this.css.markersBottom);
    }
  }

  positionWave(topMarkersIndent = 28) {
    let topValue = this.currentMarker.el.offsetTop;
    if (this.markersHasTopIndent()) {
      topValue += topMarkersIndent;
    }
    this.wave.style.top = `${topValue}px`;
  }

  markersHasTopIndent() {
    return this.markersEl.classList.contains(this.css.markersTop);
  }

  tick() {
    const timestamp = this.clock.currentTimestamp();
    const nextHighTide = this.tides.nextHighTide();
    const nextLowTide = this.tides.nextLowTide();

    this.countdowns.update({ timestamp, nextHighTide, nextLowTide });
    this.currentMarker.updateTime(timestamp);

    if (this.tickNumber !== undefined) {
      this.tickNumber += 1;
      if (this.tickNumber < 60) return;
    }
    this.tickNumber = 0;

    const { prev, next } = this.tides.nextAndPrevTides();
    const current = this.tides.currentTide();

    this.currentMarker.update({ prev, next, current });

    this.highMarker.update(nextHighTide);
    this.highMarker.toggle(current.height < nextHighTide.height);
    this.highMarker.toggleTransparent(next !== nextHighTide);

    this.lowMarker.update(nextLowTide);
    this.lowMarker.toggle(current.height > nextLowTide.height);
    this.lowMarker.toggleTransparent(next !== nextLowTide);

    const showMidMarker =
      current.height < nextHighTide.height &&
      current.height > nextLowTide.height;
    this.midMarker.toggle(showMidMarker);

    this.addIndentToMarkersIfNeeded();
    this.positionWave();

    this.tideState.update(this.tides.currentTideState());
  }
}

export default LiveTide;
