import 'core-js/features/promise';

import 'core-js/features/object/assign';
import 'core-js/features/object/is';
import 'core-js/features/object/values';
import 'core-js/features/object/entries';
import 'core-js/features/object/from-entries';
import 'core-js/features/object/get-own-property-descriptors';

import 'core-js/features/array/from';
import 'core-js/features/array/of';
import 'core-js/features/array/copy-within';
import 'core-js/features/array/fill';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import 'core-js/features/array/includes';
import 'core-js/features/array/keys';
import 'core-js/features/array/values';

import 'core-js/features/string/includes';

import 'whatwg-fetch';

import closest from './closest';
import dataset from './dataset';
import remove from './remove';
import append from './append';
import replaceWith from './replace-with';
import requestIdleCallbackPolyfill from './request-idle-callback';
import NodeListforEach from './node-list-for-each';

export default function () {
  closest();
  dataset();
  remove();
  append();
  replaceWith();
  requestIdleCallbackPolyfill();
  NodeListforEach();
}
