// from https://github.com/discontinued/element-dataset/blob/master/src/index.jxs
export default function elementDatasetPolyfill() {
  if (
    !Object.getOwnPropertyDescriptor(SVGElement.prototype, 'dataset') ||
    !Object.getOwnPropertyDescriptor(SVGElement.prototype, 'dataset').get
  ) {
    Object.defineProperty(SVGElement.prototype, 'dataset', {
      enumerable: true,
      get() {
        const map = {};

        const element = this;
        const { attributes } = this;

        function toUpperCase(n0) {
          return n0.charAt(1).toUpperCase();
        }

        function getter() {
          return this.value;
        }

        function setter(name, value) {
          if (typeof value !== 'undefined') {
            this.setAttribute(name, value);
          } else {
            this.removeAttribute(name);
          }
        }

        for (let i = 0; i < attributes.length; i += 1) {
          const attribute = attributes[i];

          // This test really should allow any XML Name without
          // colons (and non-uppercase for XHTML)

          if (
            attribute &&
            attribute.name &&
            /^data-\w[\w-]*$/.test(attribute.name)
          ) {
            const { name, value } = attribute;

            // Change to CamelCase

            const propName = name.substr(5).replace(/-./g, toUpperCase);

            Object.defineProperty(map, propName, {
              enumerable: true,
              get: getter.bind({ value: value || '' }),
              set: setter.bind(element, name),
            });
          }
        }

        return map;
      },
    });
  }
}
