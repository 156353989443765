import './index.scss';
import './static/moonset.svg';
import './static/moonrise.svg';
import './static/sunset.svg';
import './static/sunrise.svg';
import './static/moon-new-moon.svg';
import './static/moon-first-quarter.svg';
import './static/moon-full-moon.svg';
import './static/moon-last-quarter.svg';

import 'd3-transition';
import TideTable from './table';

export default function(data, loadBanner) {
  new TideTable(data, loadBanner);
}
