import screenfull from 'screenfull';

import View from './view';

import ScreenOrientation from 'utils/screen-orientation';
import './index.scss';

const getWidth = () =>
  Math.max(window.innerWidth, document.documentElement.clientWidth);
const getHeight = () =>
  Math.max(window.innerHeight, document.documentElement.clientHeight);

const checkOrientation = () => {
  const type = ScreenOrientation.type();

  if (type) return type.match('portrait') ? 'portrait' : 'landscape';

  return getHeight() > getWidth() ? 'portrait' : 'landscape';
};

const getSizes = () => {
  let width = getWidth();
  let height = getHeight();

  if (checkOrientation() === 'portrait') [width, height] = [height, width];

  return { width, height };
};

const lockOrientation = isFullscreen => {
  if (isFullscreen) {
    const result = ScreenOrientation.lock(ScreenOrientation.type());
    if (result.then) result.then(null, error => console.warn(error.message)); // eslint-disable-line no-console
  } else {
    ScreenOrientation.unlock();
  }
};

function popStateOnce(callback) {
  function onPopState() {
    callback();
    window.removeEventListener('popstate', onPopState);
  }

  window.addEventListener('popstate', onPopState);

  return () => window.removeEventListener('popstate', onPopState);
}

class FullscreenFrame {
  constructor({ content, onResize = null, onClose = null }) {
    this.onResize = onResize;
    this.onClose = onClose;
    this.view = View({ content });
    this.view.setHandlerToCloseButton('click', () => this.close());
  }

  show() {
    this.resize();

    this.view.append(document.body);

    this.view.setHandler('click', () => {
      this.resize();
      this.view.toggleMenu(true);
    });

    if (screenfull.enabled) screenfull.request(this.view.get());

    this.view.toggleMenu(true);

    this.timer = setInterval(() => this.resize(), 50);

    if (screenfull.enabled) {
      screenfull.on('change', () => {
        if (!screenfull.isFullscreen) this.close();
        lockOrientation(screenfull.isFullscreen);
      });
    }

    window.addEventListener('orientationchange', () => {
      setTimeout(() => this.resize(), 0);
    });

    window.history.pushState(
      { reason: 'fullscreen' },
      'fullscreen',
      window.location.href
    );

    this.removePopStateListener = popStateOnce(() => this.close());
  }

  close() {
    if (this.timer) clearInterval(this.timer);
    if (screenfull.enabled && screenfull.isFullscreen) screenfull.exit();
    this.view.remove();

    if (this.removePopStateListener) {
      window.history.back();
      this.removePopStateListener();
      this.removePopStateListener = null;
    }

    if (this.onClose) this.onClose();
  }

  resize() {
    this.view.changeOrientation(ScreenOrientation.type() || checkOrientation());

    const size = getSizes();
    this.view.setSize(size);
    if (this.onResize) this.onResize(size);
  }
}

export default FullscreenFrame;
