import { getCookie, setCookie } from 'utils/cookies'

const cookieName = 'pl'
const cookieDuration = 5 * 365 * 86400 * 1000 // 5 years
const minRefreshDelay = 86400 * 1000

function getPosInfoFromCookie() {
  const posStr = getCookie(cookieName)
  if (!posStr) return false

  return JSON.parse(posStr)
}

function isPosCookieFresh(): Boolean {
  const posInfo = getPosInfoFromCookie()
  if (!posInfo) {
    return false
  }

  const date = new Date()
  return posInfo.ts > date.getTime() - minRefreshDelay
}

export function updatePosCookie(pos) {
  if (!pos || !pos.coords || !pos.coords.latitude) return

  setCookie(
    cookieName,
    `{"co":{"la":${pos.coords.latitude},"lo":${pos.coords.longitude},"ac":${pos.coords.accuracy}},"ts":${pos.timestamp}}`,
    cookieDuration
  )
}

export function refreshPosCookie() {
  if (isPosCookieFresh()) return
  if (!navigator.permissions) return

  // eslint-disable-next-line compat/compat
  navigator.permissions
    .query({
      name: 'geolocation'
    })
    .then(({ state }) => {
      if (state === 'granted') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              updatePosCookie(pos)
            },
            null,
            { enableHighAccuracy: false, maximumAge: minRefreshDelay }
          )
        }
        // setCookie("pl", );
      } else {
        // console.log('Location access is NOT granted')
      }
    })
}
