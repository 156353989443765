import airbrake from 'utils/airbrake';
import { WindIcon } from 'components/wind-icon';
import { TideTime } from 'components/tide-time';

import ForecastTableDay from './forecast-table-day';
import css from './styles';

const partsPerDay = 4;

export default class TideTableDay extends ForecastTableDay {
  constructor({ table, day, cells, level }) {
    super(cells);
    this.table = table;
    this.day = day;
    this.level = level;
    this.loading = false;
  }

  cellsUrl(level) {
    return this.table.cellsUrl(this.day.date, level);
  }

  initCell(td, rowI) {
    if (rowI < 2) td.addEventListener('click', () => this.expandClicked());
    else if (rowI < 4) TideTime.init(td)
    else if (rowI === 6) WindIcon.init(td);
  }

  expandClicked() {
    if (this.loading) return;

    const headerCells = this.cells[this.level][1];
    const loadingCell = headerCells[headerCells.length - 1];
    loadingCell.classList.add(`${css.partHeader}--loading`);
    this.loading = true;

    const newLevel = this.level === 0 ? 1 : 0;
    this.changeLevel(newLevel).then(newRows => {
      if (newRows) {
        const numWeatherCols = newRows.length > 6 ? newRows[6].length : 0;
        const numCols = newLevel === 0 ? 1 : partsPerDay;
        this.table.colsAdded(numCols, numWeatherCols);
      }

      this.level = newLevel;
      this.table.levelChanged();
    }).catch(e => {
      airbrake.notify(e);
    }).finally(() => {
      this.loading = false;
      loadingCell.classList.remove(`${css.partHeader}--loading`);
    });
  }
}
