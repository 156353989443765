import { BaseComponent } from '../../utils/base-component'

import './index.scss'
import './static/nearby-location--blue.svg'
import './static/nearby-location--white.svg'

type Config = {
  positionDetermined?: (pos: GeolocationPosition) => void
}

export class Nearby extends BaseComponent<Config> {
  static cssClass = 'nearby'
  nearestPath?: string
  positionDetermined?: (pos: GeolocationPosition) => void

  init() {
    this.nearestPath = this.root.dataset.nearestPath

    if (this.config && this.config.positionDetermined)
      this.positionDetermined = this.config.positionDetermined

    this.root.addEventListener('click', (e) => {
      e.preventDefault()

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            if (this.positionDetermined) this.positionDetermined(pos)

            this.redirectToNearest(pos)
          },
          () => this.fallback(),
          { enableHighAccuracy: true }
        )
      } else {
        this.fallback()
      }
    })
  }

  redirectToNearest(position: GeolocationPosition) {
    const { latitude, longitude } = position.coords
    const { nearestPath } = this.root.dataset
    window.location.href = `${nearestPath}?lat=${latitude}&lon=${longitude}`
  }

  fallback() {
    const root = this.root as HTMLAnchorElement
    window.location.href = root.href
  }
}
