export class BElement {
  readonly element: HTMLElement

  constructor(element: Element, readonly cssClass: string) {
    this.element = element as HTMLElement
  }

  modifierClassName(name: string) {
    return `${this.cssClass}--${name}`
  }

  hasModifier(name: string) {
    const css = this.modifierClassName(name)
    return this.element.classList.contains(css)
  }

  toggleModifier(name: string, force?: boolean) {
    const css = this.modifierClassName(name)
    this.element.classList.toggle(css, force)
  }

  setHTML(html: string) {
    this.element.innerHTML = html
  }

  getText() {
    return this.element.textContent
  }

  setText(text: string) {
    this.element.textContent = text
  }
}
