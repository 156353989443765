import units from 'utils/units';

function switchUnits(container, { labelMarginTop = 4 } = {}) {
  const isMetric = units.current.length == 'Metric';
  const { scale } = window.flgraphUnitsInfo[isMetric ? 'Metric' : 'US'];

  [...container.querySelectorAll('.flscalesvg--js')].forEach((axis) => {
    const labels = axis.querySelectorAll('.flscale-label');
    const maxNumberOfLabels = Math.max(scale.length, labels.length);

    for (let i = 0; i < maxNumberOfLabels; i++) {
      const label = labels[i];

      if (label) {
        if (!scale[i]) {
          label.remove();
        } else {
          label.setAttribute('y', Number(scale[i].y) + labelMarginTop);
          if (!label.classList.contains('flscale-unit')) continue;
          label.textContent = scale[i].val;
          label.textContent += isMetric ? 'm' : 'ft';
        }
        continue;
      }

      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      text.classList.add('flscale-label');
      text.classList.add('flscale-unit');
      text.setAttribute('x', 0);
      text.setAttribute('y', scale[i].y);
      text.textContent = scale[i].val;
      text.textContent += isMetric ? 'm' : 'ft';
      axis.append(text);
    }
  });
}

export function FreezingGraphAxis(container) {
  if (!window.flgraphUnitsInfo) return;

  switchUnits(container);
  units.onChange(() => switchUnits(container));
}
