const styles = {
  container: 'tide-table__container',
  axisCell: 'tide-table__axis',
  chartCell: 'tide-table__chart',

  tableContainer: 'tide-table__table-container',
  table: 'tide-table__table',
  partDay: 'tide-table__day',
  partHeader: 'tide-table__part-header',
  partHigh: 'tide-table__part--high',
  partLow: 'tide-table__part--low',
  partSun: 'tide-table__part--sun',
  partMoon: 'tide-table__part--moon',
  partWind: 'tide-table__part--wind',
  partWeather: 'tide-table__part--weather',
  valueHigh: 'tide-table__value-high',
  valueLow: 'tide-table__value-low',
  heightHigh: 'tide-table__height-high',
  heightLow: 'tide-table__height-low',
  scroller: 'tide-table__scroller',
  bannerCell: 'tide-table__part--tbn',
  banner: 'tide-table__tbn',
  poweredCell: 'tide-table__part--powered-by',
  tideTime: 'tide-table__tide-time',
  hidden: 'tide-table__hidden',
  height: 'tide-table__height',
  units: 'tide-table__units',

  chart: 'tide-table-chart',
  arrow: 'tide-table-chart__arrow',
  day: 'tide-table-chart__day',
  night: 'tide-table-chart__night',
  blueLine: 'tide-table-chart__blue-line',
  greyLine: 'tide-table-chart__grey-line',
  liveLine: 'tide-table-chart__live-line',
  area: 'tide-table-chart__area',
  highDot: 'tide-table-chart__high-dot',
  lowDot: 'tide-table-chart__low-dot',
  touchDot: 'tide-table-chart__touch-dot',
  liveDot: 'tide-table-chart__live-dot',
  liveLabel: 'tide-table-chart__live-label',
  tooltip: 'tide-table-chart__tooltip',
  tooltips: 'tide-table-chart__tooltips',
};

export default styles;
