import { CELL_WIDTH } from './constants';

/**
 * Reads the points attribute from a curve line and transforms to a two-dimensional array
 * @param {SVGPolylineElement} curvedLine - a curved line represents the freezing level.
 * @returns {Array} a two-dimensional array containing [x, y] points
 */
export function getPoints(curvedLine) {
  return curvedLine
    .getAttribute('points')
    .split(' ')
    .map(pair => pair.split(','));
}

/**
 * Mutates an x value based on position
 * @param {Array} point - contains [x, y] values
 * @param {Number} position - a position in a row where we need to place the point on x scale
 * @returns {void}
 */
function setPointPosition(point, position) {
  /* eslint-disable-next-line no-param-reassign */
  point[0] = CELL_WIDTH / 2 + position * CELL_WIDTH;
}

/**
 * An interface helps to manipulate .curve-line points within an svg
 * @param {SVGSVGElement} svg
 * @returns {Object} api
 */
function Polyline(svg) {
  const curvedLines = [...svg.querySelectorAll('.curve-line')];
  const points = curvedLines.map(l => getPoints(l));

  const api = {
    /**
     * Deletes a particular amount of elements and adds new points to the start point.
     * Updates points positionating.
     * @param {Number} start - an index
     * @param {Number} deleteCount - amount of points to delete
     * @param {Array.<Array>} items - a list of new points
     * @returns {Function} a side effect mutate function to do the splice
     */
    splice(start, deleteCount, items) {
      for (const i in items) {
        points[i].splice(start, deleteCount, ...items[i]);
        points[i].forEach((point, j) => {
          setPointPosition(point, j);
        });
      }

      return function run() {
        for (const i in curvedLines) {
          curvedLines[i].setAttribute(
            'points',
            points[i].map(pair => pair.join(',')).join(' ')
          );
        }
      };
    },
  };

  return Object.freeze(api);
}

export default Polyline;
