import createSVGElement from 'utils/create-svg-element';

export default class ArrowCircle {
  constructor(el) {
    if (el) {
      this.el = el;
      this.polygon = el.querySelector('polygon');
      this.connector = el.querySelector('rect');
    } else {
      this.el = createSVGElement('g');
      this.polygon = createSVGElement('polygon');
      this.el.appendChild(this.polygon);
      this.connector = createSVGElement('rect');
      this.el.appendChild(this.connector);
    }
  }

  update({ angle,
           radius = 10.5,
           arrowheadHeight = 6,
           arrowheadThickness = 14,
           connectorHeight = 4,
           connectorThickness = 4 }) {
    const a = parseInt(angle, 10);
    if (Number.isNaN(a)) return;

    const connectorCircleOverlap = 1;
    // A - connector top left point
    // M - triangle left point
    // N - triangle top point
    // W - triangle right point
    const yA = -(radius + connectorHeight - connectorCircleOverlap);
    const yM = yA;
    const yW = yA;
    const yN = yM - arrowheadHeight;
    const xM = -(arrowheadThickness / 2);
    const xW = arrowheadThickness / 2;
    const xN = 0;
    const xA = -(connectorThickness / 2);

    this.el.setAttribute('transform', `rotate(${angle})`);

    this.polygon.setAttribute('points', `${xM} ${yM} ${xN} ${yN} ${xW} ${yW}`);

    this.connector.setAttribute('x', xA);
    this.connector.setAttribute('y', yA);
    this.connector.setAttribute('width', connectorThickness);
    this.connector.setAttribute('height', connectorHeight);
  }
}
