import select from 'd3-selection/src/select';
import scaleLinear from 'd3-scale/src/linear';
import tidesData from 'utils/tide/tides';

import Chart from './chart';
import StickyBanner from './sticky-banner';
import StickyNav from './sticky-nav';
import TideTableDay from './day';
import css from './styles';

const cellWidth = 60;
const numTicks = 9;
const chartHeight = 285;
const borderWidth = 1;

export default class TideTable {
  constructor({
    tideDays,
    firstTide,
    lastTide,
    startTimestamp,
    minHeight,
    maxHeight,
    mode,
    filenamePart
  }, loadBanner) {
    this.filenamePart = filenamePart;
    this.tableContainer = document.querySelector(`.${css.tableContainer}`);
    this.chartCell = document.querySelector(`.${css.chartCell}`);
    this.bannerCell = document.querySelector(`.${css.bannerCell}`);
    this.poweredCell = document.querySelector(`.${css.poweredCell}`);

    const tableNode = document.querySelector(`.${css.table}`);
    if (!tableNode) return;

    const cells = [
      css.partDay,
      css.partHeader,
      css.partHigh,
      css.partLow,
      css.partSun,
      css.partMoon,
      css.partWind,
      css.partWeather,
    ].map(selector => tableNode.querySelectorAll(`.${selector}`));

    const withoutEmpty = (...args) => [...args].filter(i => i);

    this.days = tideDays.map((day, i) => {
      const data = {
        table: this,
        day,
        cells: [null, null],
        level: 0,
      };

      if (mode === '7days') {
        data.level = 1;
      } else if (mode === 'live') {
        if (i === 0) {
          data.level = 1;
          data.cells[1] = cells.map((cs, csi) => {
            if (csi === 0) return withoutEmpty(cs[0]);
            else if (csi === 1) return withoutEmpty(cs[0], cs[1]);
            else return withoutEmpty(cs[0], cs[1], cs[2], cs[3]);
          });
        } else {
          data.cells[0] = cells.map((cs, csi) => {
            if (csi === 0) return withoutEmpty(cs[i]);
            else if (csi === 1) return withoutEmpty(cs[i + 1]);
            else return withoutEmpty(cs[i + 3]);
          });
        }
      }

      return new TideTableDay(data);
    });

    this.chart = Chart(
      this.chartCell,
      document.querySelector(`.${css.axisCell}`),
      startTimestamp,
      this.days,
      firstTide,
      lastTide,
      minHeight,
      maxHeight,
      cellWidth,
      chartHeight,
      numTicks,
      mode
    );

    StickyBanner(this.chart.hideTooltip, loadBanner);

    StickyNav();

    if (mode === 'live') {
      tidesData.liveTide(tide => this.chart.live(tide));
    }
  }

  cellsUrl(date, level) {
    let url = `/locations/${this.filenamePart}/tides/${date}/expand.json`;
    if (level === 0) url += '?collapse=1';
    return url;
  }

  colsAdded(numCols, numWeatherCols) {
    if (this.bannerCell) this.bannerCell.colSpan += numCols - numWeatherCols;
    this.poweredCell.colSpan += numCols;
    this.chartCell.colSpan += numCols;
  }

  levelChanged() {
    const newWidth = this.chart.render(this.days);
    this.tableContainer.style.width = `${newWidth + borderWidth * 2}px`;
  }
}
