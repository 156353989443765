import { findUpperSelector } from 'components/utils';

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const changeOptions = (el, data) => {
  [...el.options].forEach(x => x.remove());
  data.forEach(x => {
    const option = new Option(x.v, x.k);
    el.add(option);
  });
};

const switchLoading = el => {
  const select = findUpperSelector(el, 'select');
  if (!select) return;

  const classname = 'is-loading';
  if (select.classList && select.classList.contains(classname))
    select.classList.remove(classname);
  else select.classList.add(classname);
};

window.addEventListener('load', () => {
  const countrySelect = document.getElementById('country_id');
  const regionSelect = document.getElementById('region_id');
  const locationSelect = document.getElementById('location_filename_part');

  if (countrySelect)
    countrySelect.addEventListener('change', e => {
      const { value } = e.target;

      if (value) {
        if (regionSelect) switchLoading(regionSelect);
        if (locationSelect) switchLoading(locationSelect);

        fetch(`/countries/${value}/regions.json`, { credentials: 'include' })
          .then(checkStatus)
          .then(response => response.json())
          .then(data => {
            if (regionSelect) {
              if (Object.hasOwnProperty.call(data, 'regions')) {
                const container = findUpperSelector(regionSelect);
                if (container) container.classList.remove('is-hidden');
                changeOptions(regionSelect, data.regions);
              } else {
                const container = findUpperSelector(regionSelect, 'field');
                if (container) container.classList.add('is-hidden');
              }

              switchLoading(regionSelect);
            }
            if (locationSelect) {
              if (Object.hasOwnProperty.call(data, 'locations')) {
                changeOptions(locationSelect, data.locations);
              }

              switchLoading(locationSelect);
            }
          })
          .catch(() => {
            // TODO: Notify a user what happened.
            switchLoading(regionSelect);
            switchLoading(locationSelect);
          });
      }
    });

  if (regionSelect)
    regionSelect.addEventListener('change', e => {
      const { value } = e.target;

      if (value) {
        if (locationSelect) switchLoading(locationSelect);

        fetch(`/regions/${value}/locations.json`)
          .then(checkStatus)
          .then(response => response.json())
          .then(data => {
            if (locationSelect) {
              if (Object.hasOwnProperty.call(data, 'locations')) {
                changeOptions(locationSelect, data.locations);
              }

              switchLoading(locationSelect);
            }
          })
          .catch(() => {
            // TODO: Notify a user what happened.
            switchLoading(locationSelect);
          });
      }
    });

  if (locationSelect)
    locationSelect.addEventListener('change', e => {
      e.preventDefault();

      const { value } = e.target;
      if (value) window.location.href = `/locations/${value}/tides/latest`;
    });

  const formSelect = document.getElementById('dropform');
  if (formSelect)
    formSelect.addEventListener('submit', e => {
      e.preventDefault();

      const { value } = locationSelect;
      if (value) window.location.href = `/locations/${value}/tides/latest`;
    });
});
