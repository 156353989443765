function smoothScroll(callback, distance) {
  let previous = null;
  let progress = 0;

  // eslint-disable-next-line compat/compat
  return new Promise(resolve => {
    function step(timestamp) {
      if (previous === null) previous = timestamp;

      /* eg cases:
        perfect: 16 / 3 + 114 / 50 = 8(px/frame)
        perfect long mouse holding: 16 / 3 + 228 = 10(px/frame)
        delay: 50 / 3 + 114 / 50 = 19(px/frame)
      */
      const shift = Math.min(
        distance - progress,
        Math.round((timestamp - previous) / 3 + distance / 50)
      );
      progress += shift;
      previous = timestamp;
      callback(Math.max(shift, 0));

      if (progress < distance) {
        requestAnimationFrame(step);
        return;
      }

      resolve();
    }

    requestAnimationFrame(step);
  });
}

export default smoothScroll;
