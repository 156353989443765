import Frame from './frame';
import Menu from './menu';
import CloseButton from './close-button';

const View = ({ content }) => {
  const menu = Menu();
  const closeButton = CloseButton();
  menu.add(closeButton.get());

  const frame = Frame();
  frame.add(content).add(menu.get());

  const api = {
    get() {
      return frame.get();
    },
    append(parent) {
      frame.append(parent);

      return api;
    },
    remove(deep) {
      if (deep) {
        closeButton.remove();
        menu.remove();
      }

      frame.remove();

      return api;
    },
    setHandler(type, cb) {
      frame.setHandler(type, cb);

      return api;
    },
    setHandlerToMenu(type, cb) {
      menu.setHandler(type, cb);

      return api;
    },
    setHandlerToCloseButton(type, cb) {
      closeButton.setHandler(type, cb);

      return api;
    },
    setSize({ width, height }) {
      if (typeof width === 'number' && typeof height === 'number')
        frame.setSize({ width, height });

      return api;
    },
    changeOrientation(type) {
      if (type.match(/portrait|landscape/)) frame.changeOrientation(type);

      return api;
    },
    toggleMenu(force) {
      if (force || menu.get().classList.contains('is-moved')) menu.show();
      else menu.hide();

      return api;
    },
  };

  return Object.freeze(api);
};

export default View;
