import Clock from 'utils/clock';

export class Tides {
  constructor({ clock }) {
    this.clock = clock;
    this.currentListeners = [];
  }

  loadHighLowTides({ timeline, highTides, lowTides }) {
    this.timeline = timeline;
    this.highTides = highTides;
    this.lowTides = lowTides;
    this.highAndLowTides = this.highTides.concat(this.lowTides);
    this.highAndLowTides.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
  }

  fromDays(days) {
    this.timeline = [];
    this.highAndLowTides = [];
    days.forEach(d => {
      d.tides.forEach(t => {
        (t.type ? this.highAndLowTides : this.timeline).push(t);
      });
    });
    this.highTides = this.highAndLowTides.filter(t => t.type === 'high');
    this.lowTides = this.highAndLowTides.filter(t => t.type === 'low');
  }

  liveTide(onChange) {
    if (!this.current) {
      this.current = this.currentTideWithType();
    }

    onChange(this.current);
    this.currentListeners.push(onChange);

    if (this.updater) return;

    this.updater = setInterval(() => {
      const current = this.currentTideWithType();

      if (
        current.type !== this.current.type ||
        current.height !== this.current.height ||
        current.timestamp !== this.current.timestamp
      ) {
        this.current = current;
        this.currentListeners.forEach(f => f(current));
      }
    }, 1000);
  }

  currentTide() {
    const timestamp = this.clock.currentTimestamp();

    for (let i = 0; i < this.timeline.length; i += 1) {
      if (this.timeline[i].timestamp > timestamp) {
        return this.timeline[i];
      }
    }

    return false;
  }

  currentImagePos() {
    const timestamp = this.clock.currentTimestamp();

    for (let i = 0; i < this.timeline.length; i += 1) {
      if (this.timeline[i].timestamp > timestamp) {
        const oldX = this.timeline[i - 1].x;
        const oldY = this.timeline[i - 1].y;
        const newX = this.timeline[i].x;
        const newY = this.timeline[i].y;
        const oldTimestamp = this.timeline[i - 1].timestamp;
        const newTimestamp = this.timeline[i].timestamp;
        const completion =
              (1.0 * (timestamp - oldTimestamp)) / (newTimestamp - oldTimestamp);
        const x = oldX + (newX - oldX) * completion;
        const y = oldY + (newY - oldY) * completion;
        return [x, y];
      }
    }

    return null;
  }

  currentTideWithType() {
    const type = this.currentTideState();
    const { timestamp, height } = this.currentTide();

    return { type, timestamp, height };
  }

  nextHighTide(timestamp = null) {
    const ts = timestamp || this.clock.currentTimestamp();

    for (let i = 0; i < this.highTides.length; i += 1) {
      if (this.highTides[i].timestamp > ts) {
        return this.highTides[i];
      }
    }

    return false;
  }

  nextLowTide(timestamp = null) {
    const ts = timestamp || this.clock.currentTimestamp();

    for (let i = 0; i < this.lowTides.length; i += 1) {
      if (this.lowTides[i].timestamp > ts) {
        return this.lowTides[i];
      }
    }

    return false;
  }

  nextAndPrevTides() {
    const currentTimestamp = this.clock.currentTimestamp();
    let next;
    let prev;

    for (let i = 0; i < this.highAndLowTides.length; i += 1) {
      if (this.highAndLowTides[i].timestamp > currentTimestamp) {
        next = this.highAndLowTides[i];
        break;
      }
      prev = this.highAndLowTides[i];
    }

    return { prev, next };
  }

  nextTide() {
    const currentTimestamp = this.clock.currentTimestamp();

    for (let i = 0; i < this.highAndLowTides.length; i += 1) {
      if (this.highAndLowTides[i].timestamp > currentTimestamp) {
        return this.highAndLowTides[i];
      }
    }

    return false;
  }

  currentTideState() {
    return this.nextTide().type === 'high' ? 'rising' : 'falling';
  }
}

const tides = new Tides({ clock: Clock });

export function loadTides() {
  if (!window.FCGON) return;

  if (window.FCGON.tideDays) {
    tides.fromDays(window.FCGON.tideDays);
  } else if (window.highTides && window.lowTides) {
    tides.loadHighLowTides({
      timeline: window.FCGON.timeline,
      highTides: window.highTides,
      lowTides: window.lowTides,
    });
  }
}

export default tides;
