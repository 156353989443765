class TideState {
  constructor({ css, el }) {
    this.css = css;
    this.el = el;

    this.text = el.querySelector('span');
    this.img = el.querySelector('img');

    el.classList.remove(css.tideStateInvisible);
  }

  update(currentTideState) {
    if (currentTideState === 'rising') {
      this.setTransform('rotate(0deg)');
      this.text.innerHTML = 'rising';
    } else {
      this.setTransform('rotate(180deg)');
      this.text.innerHTML = 'falling';
    }
  }

  setTransform(style) {
    this.img.style.webkitTransform = style;
    this.img.style.MozTransform = style;
    this.img.style.msTransform = style;
    this.img.style.OTransform = style;
    this.img.style.transform = style;
  }
}

export default TideState;
