const Frame = () => {
  const wrapper = document.createElement('div');
  wrapper.className = 'fullscreen-container';
  const container = document.createElement('div');
  container.className = 'fullscreen-container';
  const frame = document.createElement('div');
  frame.className = 'fullscreen-frame';
  container.appendChild(frame);
  wrapper.appendChild(container);

  let handlers = [];
  let isRotated = false;

  const api = {
    get() {
      return wrapper;
    },
    append(parent) {
      parent.appendChild(wrapper);

      return api;
    },
    remove() {
      handlers.forEach(x => container.removeEventListener(x.type, x.cb));
      handlers = [];

      if (wrapper.parentNode) wrapper.parentNode.removeChild(wrapper);

      return api;
    },
    add(newElement) {
      frame.appendChild(newElement);

      return api;
    },
    setHandler(type, cb) {
      handlers.push({ type, cb });
      container.addEventListener(type, cb);

      return api;
    },
    setSize({ width, height }) {
      container.style.width = `${width}px`;

      let transformString = '';
      if (isRotated) {
        transformString = `rotate(90deg) translateY(-${height}px)`;
      }
      container.style['-webkit-transform'] = transformString;
      container.style.transform = transformString;

      return api;
    },
    changeOrientation(type) {
      if (type.match('portrait')) isRotated = true;
      if (type.match('landscape')) isRotated = false;

      return api;
    },
  };

  return Object.freeze(api);
};

export default Frame;
