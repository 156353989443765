import fastdom from 'fastdom';

import generateUpdateFunction from './helpers/generate-update-fn';
import initialState from './helpers/initial-state';
import mouseListener from './helpers/mouse-listeners';

import throttle from 'utils/throttle';

import './scroll-button.scss';
import './static/icon-chevron-circle-right.svg';
import './static/icon-chevron-circle-left.svg';

function ScrollButtons(container) {
  const scrollContainer = container.querySelector('[data-scroll-container]');
  const buttonLeft = container.querySelector('[data-scroll-button=left]');
  const buttonRight = container.querySelector('[data-scroll-button=right]');

  const update = generateUpdateFunction(
    buttonLeft,
    buttonRight,
    scrollContainer
  );

  const scrollListener = throttle(update, 500);
  const mutationListener = new MutationObserver(throttle(update, 500));
  const ButtonLeftListener = mouseListener(value => {
    fastdom.mutate(() => {
      scrollContainer.scrollLeft -= value;
    });
  });
  const ButtonRightListener = mouseListener(value => {
    fastdom.mutate(() => {
      scrollContainer.scrollLeft += value;
    });
  });

  function enable() {
    scrollContainer.addEventListener('scroll', scrollListener);
    mutationListener.observe(scrollContainer, {
      childList: true,
      subtree: true,
    });
    buttonLeft.addEventListener('mousedown', ButtonLeftListener);
    buttonRight.addEventListener('mousedown', ButtonRightListener);
  }

  function disable() {
    scrollContainer.removeEventListener('scroll', scrollListener);
    mutationListener.disconnect();
    buttonLeft.removeEventListener('mousedown', ButtonLeftListener);
    buttonRight.removeEventListener('mousedown', ButtonRightListener);
  }

  window.addEventListener(
    'resize',
    throttle(() => {
      if (window.matchMedia('only screen and (min-width: 948.001px)').matches) {
        disable();
        enable();
        update();
      } else disable();
    }, 3000)
  );

  initialState(buttonLeft, buttonRight, update);
  enable();

  return Object.freeze({
    enable,
    disable,
  });
}

export default function () {
  return [...document.querySelectorAll('[data-scroll]')].map(ScrollButtons);
}
