import 'components/toggle-button';
import 'components/radio-button';
import parseHtml from 'utils/parse-html';
import knotsIcon from './static/sailboat.svg';

export const CLASS_NAME = 'switch-units-selector';
export const CLASS_NAME_SPEED_KPH = 'switch-units-selector__speed-kph';
export const CLASS_NAME_SPEED_MPH = 'switch-units-selector__speed-mph';
export const CLASS_NAME_SPEED_KNOTS = 'switch-units-selector__speed-knots';
export const CLASS_NAME_LENGTH = 'switch-units-selector__length';
export const CLASS_NAME_TEMPERATURE = 'switch-units-selector__temperature';

const html = `
  <div class="${CLASS_NAME} not_in_print">
    <form action="" method="POST">
      <div class="${CLASS_NAME}__container">
        <div class="${CLASS_NAME}__controls">
          <label class="${CLASS_NAME}__control toggle-button">
            <input class="toggle-button__input ${CLASS_NAME_LENGTH}" type="checkbox" />
            <span class="toggle-button__first">m</span>
            <span class="toggle-button__slider"></span>
            <span class="toggle-button__second">ft</span>
          </label>
          <label class="${CLASS_NAME}__control toggle-button">
            <input class="toggle-button__input ${CLASS_NAME_TEMPERATURE}" type="checkbox" />
            <span class="toggle-button__first">°C</span>
            <span class="toggle-button__slider"></span>
            <span class="toggle-button__second">°F</span>
          </label>
        </div>

        <div class="${CLASS_NAME}__controls">
          <label class="${CLASS_NAME}__control radio-button">
            <input class="${CLASS_NAME_SPEED_KPH} radio-button__input" type="radio" name="speed" value="Metric" />
            <span class="radio-button__label">km/h</span>
            <span class="radio-button__button"></span>
          </label>
          <label class="${CLASS_NAME}__control radio-button">
            <input class="${CLASS_NAME_SPEED_MPH} radio-button__input" type="radio" name="speed" value="Imperial" />
            <span class="radio-button__label">mph</span>
            <span class="radio-button__button"></span>
          </label>
          <label class="${CLASS_NAME}__control radio-button">
            <input class="${CLASS_NAME_SPEED_KNOTS} radio-button__input" type="radio" name="speed" value="Knot" />
            <span class="radio-button__label">
              <img class="${CLASS_NAME_SPEED_KNOTS}-icon" src="${knotsIcon}" size="10x10" alt="knots icon"></img>
              knots
            </span>
            <span class="radio-button__button"></span>
          </label>
        </div>
      </div>
    </form>
  </div>
`;

export default function(withoutKnots) {
  const node = parseHtml(html).body.childNodes[0];
  if (withoutKnots) {
    node.querySelector(`.${CLASS_NAME}__container`).children[1].children[2].remove()
  }
  return node;
}
