import emitter from 'components/emitter';
import { throttle } from 'components/utils';
import './index.pcss';

const button = document.getElementById('mobile-button-sticky');

button.addEventListener('click', () => {
  button.blur();
  emitter.emit('mobile-navigation.open');
});

const buttonContainer = button.parentNode;
const classNameHidden = 'is-hidden';
const classNameHiddenEnter = 'is-hidden-enter';

function transitionHandler() {
  this.removeEventListener('transitionend', transitionHandler);
  this.classList.add(classNameHidden);
  this.classList.remove(classNameHiddenEnter);
}

const isButtonDisplayed = () =>
  !buttonContainer.classList.contains(classNameHidden) &&
  !buttonContainer.classList.contains(classNameHiddenEnter);

const hideButton = () => {
  if (!isButtonDisplayed()) return;
  buttonContainer.classList.add(classNameHiddenEnter);
  buttonContainer.addEventListener('transitionend', transitionHandler);
};

const displayButton = () => {
  if (isButtonDisplayed()) return;
  buttonContainer.classList.add(classNameHiddenEnter);
  buttonContainer.classList.remove(classNameHidden);
  setTimeout(() => {
    buttonContainer.classList.remove(classNameHiddenEnter);
  }, 0);
};

let isMenuOpen = false;

emitter.on('mobile-navigation.open', () => {
  isMenuOpen = true;
  hideButton();
});

emitter.on('mobile-navigation.close', () => {
  isMenuOpen = false;
  displayButton();
});

const header = document.getElementById('header');

const scrollHandler = () => {
  if (isMenuOpen) return;
  /* eslint-disable-next-line compat/compat */
  const windowOffset = window.scrollY || window.pageYOffset || 0;
  const headerHeight = header.offsetHeight || header.clientHeight || 0;

  if (windowOffset > headerHeight && !isButtonDisplayed()) {
    displayButton();
  }
  if (windowOffset < headerHeight && isButtonDisplayed()) {
    hideButton();
  }
};

const delayedProcess = throttle(scrollHandler, 50);

window.addEventListener('scroll', delayedProcess);
