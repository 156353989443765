import emitter from 'components/emitter';
import './index.pcss';

const switchClasses = (menu, button) => {
  button.classList.toggle('is-active');
  menu.classList.toggle('is-showed');
};

function transitionHandler() {
  this.removeEventListener('transitionend', transitionHandler);
  document.body.removeChild(this);
}

const overlayHandler = (menu, button) =>
  function handler() {
    switchClasses(menu, button);
    this.removeEventListener('click', handler);

    this.addEventListener('transitionend', transitionHandler);
    this.classList.remove('is-showed');
    emitter.emit('mobile-navigation.close');
  };

window.addEventListener('load', () => {
  const menu = document.getElementById('mobile-navigation-menu');
  const button = document.getElementById('mobile-navigation-button');

  if (button)
    button.addEventListener('click', () =>
      emitter.emit('mobile-navigation.open')
    );

  const handler = () => {
    if (!menu) return;

    button.blur();
    switchClasses(menu, button);

    const overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);
    setTimeout(() => {
      overlay.classList.add('is-showed');
      overlay.addEventListener('click', overlayHandler(menu, button));
    }, 0);
  };

  emitter.on('mobile-navigation.open', handler);
});
