import './index.pcss';

const classnames = {
  container: 'read-more',
  visible: 'visible',
  hidden: 'hidden',
  showHidden: 'show-hidden',
};

const toggleClasses = el => {
  if (el.classList.contains(classnames.hidden)) {
    el.classList.remove(classnames.hidden);
    el.classList.add(classnames.visible);
    return;
  }
  if (el.classList.contains(classnames.visible)) {
    el.classList.remove(classnames.visible);
    el.classList.add(classnames.hidden);
  }
};

const changeButtonText = (el, e) => {
  if (el.classList.contains(classnames.visible)) {
    const newText = e.dataset.hidden;

    if (newText) {
      e.dataset.backupText = e.innerText;
      e.innerText = newText;
    }
    return;
  }
  if (el.classList.contains(classnames.hidden)) {
    const { backupText } = e.dataset;

    if (backupText) {
      e.innerText = backupText;
    }
  }
};

window.addEventListener('load', () => {
  const elements = document.getElementsByClassName(classnames.container);

  [...elements].forEach(el => {
    const hiddenEl = el.querySelector(`.${classnames.hidden}`);
    const button = el.querySelector(`.${classnames.showHidden}`);

    if (button)
      button.addEventListener('click', () => {
        toggleClasses(hiddenEl);
        changeButtonText(hiddenEl, button);
      });
  });
});
