import parseHtml from 'utils/parse-html';

function tableNodeToArray(table) {
  const [tbody] = table.childNodes;
  const rows = [];
  tbody.childNodes.forEach(tr => {
    const tds = [];
    tr.childNodes.forEach(td => tds.push(td));
    rows.push(tds);
  });
  return rows;
}

export default function fetchCells(url) {
  return fetch(url)
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    })
    .then(response => response.json())
    .then(data => {
      const [partsTable] = parseHtml(data.html).body.childNodes;
      return tableNodeToArray(partsTable);
    });
}
