import './static/icon-tide-datum.svg';
import './index.scss';
import LiveTide from './live-tide';

import units from 'utils/units';
import tides from 'utils/tide/tides';
import clock from 'utils/clock';

export default function init() {
  let prefix = 'tide-header-current';
  const currentTideCss = {
    buoy: `${prefix}__buoy`,
    heightValue: `${prefix}__height-value`,
    arrow: `${prefix}__arrow`,
    arrowFalling: `${prefix}__arrow--falling`,
    text: `${prefix}__text`,
    nextTide: `${prefix}__next-tide`,
    info: `${prefix}__info`,
    infoAbove: `${prefix}__info--above-buoy`,
  };

  prefix = 'tide-header-livetide';
  const el = document.querySelector(`.${prefix}`);
  if (!el) return;

  const css = {
    wave: `${prefix}__wave`,
    markers: `${prefix}__height-markers`,
    markersTop: `${prefix}__height-markers--with-top-indent`,
    markersBottom: `${prefix}__height-markers--with-bottom-indent`,
    markerTransparent: `${prefix}__marker--transparent`,
    markerInvisible: `${prefix}__marker--invisible`,
    markerHeight: `${prefix}__marker-height`,
    markerTime: `${prefix}__marker-time`,
    current: `${prefix}__current`,
    currentTide: currentTideCss,
    highMarker: `${prefix}__high-marker`,
    lowMarker: `${prefix}__low-marker`,
    midMarker: `${prefix}__mid-marker`,
    container: `${prefix}__container`,
    containerInvisible: `${prefix}__container--invisible`,
    nextHighCountdown: `${prefix}__next-high-countdown`,
    nextLowCountdown: `${prefix}__next-low-countdown`,
    nextHighTime: `${prefix}__next-high-time`,
    nextLowTime: `${prefix}__next-low-time`,
    tideState: `${prefix}__tide-state`,
    tideStateInvisible: `${prefix}__tide-state--invisible`,
    localtime: `${prefix}__today-localtime`,
    localtimeInvisible: `${prefix}__today-localtime--invisible`,
    localtimeValue: `${prefix}__today-localtime-value`,
  };

  const liveTide = new LiveTide({ css, el, tides, clock, units });

  (function tick() {
    liveTide.tick();
    setTimeout(tick, 1000);
  })();
}
