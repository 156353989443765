import FullscreenFrame from 'components/fullscreen-frame';

export default function init() {
  if (!window.matchMedia('(max-width: 1024px)').matches) return;

  const origImage = document.querySelector('.tide-table-image__image');
  const button = document.querySelector('.tide-table-image__preview');
  if (!origImage || !button) return;

  const image = origImage.cloneNode(true);

  const fullscreenFrame = new FullscreenFrame({
    content: image,
    onResize: ({ height }) => {
      image.style.width = 'auto';
      image.style.height = `${height}px`;
    },
  });

  button.addEventListener('click', e => {
    e.preventDefault();
    fullscreenFrame.show();
  });
}
