import units from '../../utils/units'
import { BaseComponent, BElement } from '../../utils/base-component'
import './index.scss'

export class TideTime extends BaseComponent {
  static cssClass = 'tide-time'

  height!: BElement
  units!: BElement
  value!: Number

  init() {
    this.height = this.element('height')!
    this.units = this.element('units')!
    this.value = parseFloat(this.height.getText()!)
    this.render()
    units.onChange(() => this.render())
  }

  render() {
    this.height.setText(units.formatLength(this.value))
    this.units.setText(units.formatLengthUnits())
  }
}
