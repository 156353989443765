function isNumber(value) {
  return !Number.isNaN(Number(value));
}

function round(value) {
  return Math.round(value);
}

export function precisionRound(value, decimal) {
  return Number(`${round(`${value}e${decimal}`)}e-${decimal}`);
}

export function roundBy(value, by) {
  return round(value / by) * by;
}

function convert(value, settings) {
  /* eslint-disable no-param-reassign */
  if (settings) {
    if (isNumber(settings.roundBy)) value = roundBy(value, settings.roundBy);

    if (isNumber(settings.precision))
      value = precisionRound(value, settings.precision);

    if (isNumber(settings.toFixed)) value = value.toFixed(settings.toFixed);

    if (settings.round) value = round(value);
  }

  return value;
  /* eslint-enable no-param-reassign */
}

export function KmToMiles(value) {
  return value * 0.6214;
}

export function KmToKnot(value) {
  return value * 0.53996;
}

export function MetresToFeet(value) {
  return value * 3.281;
}

export function FeetToMetres(value) {
  return value / 3.281;
}

export function CelsiusToFahrenheit(value) {
  return value * 1.8 + 32;
}

export function FahrenheitToCelsius(value) {
  return (value - 32) / 1.8;
}

export function MillimetersToInches(value) {
  return value * 0.03937;
}

export function InchesToMillimeters(value) {
  return value / 0.03937;
}

export function convertCelsiusToFahrenheit(value, settings) {
  return convert(CelsiusToFahrenheit(value), settings);
}

export function convertMetersToFeet(value, settings) {
  return convert(MetresToFeet(value), settings);
}

export function convertFeetToMeters(value, settings) {
  return convert(FeetToMetres(value), settings);
}

export function convertKilometersToMiles(value, settings) {
  return convert(KmToMiles(value), settings);
}

export function convertCmToInches(value, settings) {
  return convert(value * 0.3937, settings);
}

export function convertKphToMph(value, settings) {
  return convert(KmToMiles(value), settings);
}

export function convertKphToKnot(value, settings) {
  return convert(KmToKnot(value), settings);
}

export function convertMillimetersToInches(value, settings) {
  return convert(MillimetersToInches(value), settings);
}

export function convertHectarsToAcres(value) {
 return Math.round(value * 2.471052);
}

export function convertAcresToHectars(value) {
 return Math.round(value * 0.404686);
}
