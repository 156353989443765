import readBoundaries from './read-boundaries';
import hasScrollSpaceOn from './has-scroll-space-on';
import toggleButtonVisibility from './toggle-button-visibility';

function generateUpdateFunction(buttonLeft, buttonRight, scrollContainer) {
  return function update() {
    readBoundaries(scrollContainer, (offsetWidth, scrollWidth, scrollLeft) => {
      toggleButtonVisibility.opacity(
        buttonLeft,
        hasScrollSpaceOn.left({ offsetWidth, scrollWidth, scrollLeft })
      );

      toggleButtonVisibility.opacity(
        buttonRight,
        hasScrollSpaceOn.right({ offsetWidth, scrollWidth, scrollLeft })
      );
    });
  };
}

export default generateUpdateFunction;
