import { csrfToken } from 'utils/csrf-token';

export function fetchData(url) {
  const headers = {
    Accept: 'application/json',
    'X-CSRF-Token': csrfToken(),
  };

  if (window.FCGON && window.FCGON.serverTime) {
    headers['X-Loaded-At'] = window.FCGON.serverTime;
  }

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers,
  }).then(response => {
    if (response.status === 205) {
      window.location.reload(true)
    } else if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    } else {
      return response.json();
    }
  });
}
