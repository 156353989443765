import FullscreenFrame from 'components/fullscreen-frame';

export default function init() {
  const tidesContentImage = document.querySelector('.tides-content-image--js');
  const button = document.getElementById('fullscreen-button');
  if (!tidesContentImage || !button) return;

  const ad = document.getElementById('div-gpt-ad-resp-leader-btf');
  const scrollToAd = () => {
    if (ad) {
      ad.scrollIntoView();
      window.scroll(0, window.pageYOffset - 100);
    }
  };

  const imageClone = tidesContentImage.cloneNode(true);
  const image = imageClone.querySelector('.tides-image');

  const fullscreenFrame = new FullscreenFrame({
    content: imageClone,
    onResize: ({ height }) => {
      image.style.width = 'auto';
      image.style.height = `${height}px`;
    },
    onClose: () => {
      scrollToAd();
    },
  });

  button.addEventListener('click', () => {
    fullscreenFrame.show();
  });
}
