import css from './styles';

function StickyBanner(onScroll, loadBanner) {
  const scroller = document.querySelector(`.${css.scroller}`);
  const bannerCell = document.querySelector(`.${css.bannerCell}`);
  const banner = document.querySelector(`.${css.banner}`);
  if (!bannerCell || !banner || !loadBanner) return;

  const bannerLeft = bannerCell.offsetLeft + banner.offsetLeft;
  const scrollerWidth = scroller.offsetWidth;
  const bannerWidth = banner.offsetWidth;
  let loaded = false;

  banner.style.top = `${bannerCell.offsetTop + 1}px`;
  banner.style.height = `${bannerCell.offsetHeight - 1}px`;

  const loadIfNeeded = () => {
    if (
      !loaded &&
      scroller.scrollLeft + scrollerWidth > bannerLeft + bannerWidth / 2
    ) {
      loadBanner();
      loaded = true;
    }
  };

  const scrolled = () => {
    const scroll = scroller.scrollLeft;
    const fixedClass = `${css.banner}--fixed`;

    if (scroll > bannerLeft) {
      banner.classList.add(fixedClass);
    } else {
      loadIfNeeded();
      banner.classList.remove(fixedClass);
    }

    if (onScroll) onScroll();
  };

  loadIfNeeded();
  scroller.addEventListener('scroll', scrolled);
}

export default StickyBanner;
