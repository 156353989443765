import { CELL_WIDTH, LABEL_MARGIN } from './constants';

export function getCells(parent) {
  return Array.from(parent.querySelectorAll('g.elevation')).map(elev => {
    return Array.from(elev.querySelectorAll('g.group-cell'));
  });
}

/**
 * Sets (mutates) the element transform attribute to the new one based on position
 * @param {SVGGElement} el - assumes it is .group-cell element
 * @param {Number} position - index of the element among siblings
 * @returns {void}
 */
function setCellPosition(el, position) {
  const [, y] = el
    .getAttribute('transform')
    .match(/translate\([0-9.]+,? ([0-9.]+)\)/);

  const newX = (LABEL_MARGIN + position * CELL_WIDTH).toFixed(2);

  el.setAttribute('transform', `translate(${newX} ${y})`);
}

/**
 * An interface helps to manipulate .group-cell elements within an svg
 * @param {SVGSVGElement} svg
 * @returns {Object} api
 */
function Cells(svg) {
  let cells = getCells(svg);

  const api = {
    get cells() {
      return cells;
    },
    /* eslint-disable no-loop-func */
    /**
     * Deletes a particular amount of elements and adds new cells to the start point.
     * Updates cells positionating.
     * @param {Number} start - an index
     * @param {Number} deleteCount - amount of cells to delete
     * @param {Array.<SVGGElement>} items - a list of new cells
     * @returns {Function} a side effect mutate function to do the splice
     */
    splice(start, deleteCount, items) {
      for (let i = 0; i < items.length; i += 1) {
        for (let j = 0; j < items[i].length; j += 1) {
          setCellPosition(items[i][j], start + j);
        }
      }

      return function run() {
        for (let i = 0; i < cells.length; i += 1) {
          if (deleteCount > 0) {
            for (let j = 0; j < deleteCount - 1; j += 1) {
              cells[i][start + j].remove();
            }
            cells[i][start + deleteCount - 1].replaceWith(...items[i]);
          } else {
            const parent = cells[i][start].parentNode;
            for (let j = 0; j < items[i].length; j += 1) {
              parent.insertBefore(items[i][j], cells[i][start]);
            }
          }

          let position = start + items[i].length;
          for (let j = start + deleteCount; j < cells[i].length; j += 1) {
            setCellPosition(cells[i][j], position);
            position++;
          }
        }

        cells = getCells(svg);
      };
    },
    /* eslint-enable no-loop-func */
  };

  return Object.freeze(api);
}

export default Cells;
