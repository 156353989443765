import fastdom from 'fastdom';

/**
 * Reads geometry boundaries of the element
 * @async
 * @param {HTMLElement} el - Any element in DOM
 * @param {function} next - A callback function to provide data after the function is finished.
 * @returns {void}
 */
function readBoundaries(el, next) {
  fastdom.measure(() => next(el.offsetWidth, el.scrollWidth, el.scrollLeft));
}

export default readBoundaries;
