import { LocationPointJson } from './models/location-point'

interface PointsOfInterest {
  locations: LocationPointJson[]
}

export class LeafletRepository {
  // eslint-disable-next-line class-methods-use-this
  constructLocationUri(filename: string) {
    return `/locations/${filename}/tides/latest`
  }

  // loadPointsOfInterest(bbox: [number, number, number, number]): Promise<PointsOfInterest> {
  loadPointsOfInterest(bboxString: string): Promise<PointsOfInterest> {
    const url = `/osm/points_of_interest.json?bbox=${bboxString}`
    return this.fetch(url)
  }

  loadAllPlaces(): Promise<Array<[number, number]>> {
    return this.fetch('/osm/coordinates.json')
  }

  // eslint-disable-next-line class-methods-use-this
  private async fetch<T>(url: string): Promise<T> {
    const response = await fetch(url, {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`)
    }

    let json: T
    try {
      json = await response.json()
    } catch (error) {
      throw new Error(`Failed parsing json, "${error}"`)
    }

    return json
  }
}
