function ReplaceWithPolyfill() {
  const parent = this.parentNode;
  if (!parent) return;
  // if there are no new nodes
  if (!arguments.length) {
    parent.removeChild(this);
    return;
  }

  let currentNode;
  const fragment = document.createDocumentFragment();
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < arguments.length; i++) {
    /* eslint-disable-next-line prefer-rest-params */
    currentNode = arguments[i];

    if (typeof currentNode !== 'object') {
      currentNode = this.ownerDocument.createTextNode(currentNode);
    }

    fragment.appendChild(currentNode);
  }

  parent.replaceChild(fragment, this);
}

export default function () {
  if (!Element.prototype.replaceWith)
    Element.prototype.replaceWith = ReplaceWithPolyfill;
  if (!CharacterData.prototype.replaceWith)
    CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
  if (!DocumentType.prototype.replaceWith)
    DocumentType.prototype.replaceWith = ReplaceWithPolyfill;
}
