import fastdom from 'fastdom';

function opacity(button, show) {
  fastdom.mutate(() => {
    if (show) {
      button.classList.remove('is-stealthy');
      return;
    }

    button.classList.add('is-stealthy');
  });
}

function display(button, show) {
  fastdom.mutate(() => {
    if (show) {
      button.classList.remove('is-hidden');
      button.classList.add('is-shown');
      return;
    }

    button.classList.add('is-hidden');
    button.classList.remove('is-shown');
  });
}

export default Object.freeze({
  opacity,
  display,
});
