import FreezingGraph from './freezing-graph';
import { FreezingGraphAxis } from './freezing-graph/axis';
import ForecastTableDay from './day';
import { dayCellsDate } from './cells';

function dayCellsDetail(cells) {
  // eslint-disable-next-line prefer-destructuring
  const mode = cells.days[0].dataset.mode;
  if (mode === 'h') return 2;
  if (mode === 't') return 1;
  return 0;
}

export default class ForecastTableContent {
  constructor(table, data, elev) {
    this.root = table;
    this.data = data;
    this.level = elev;
    this.todayIndex = 0;

    const { cells, graph } = data.parse(table);

    if (graph) {
      this.graphCell = graph;
      this.graph = FreezingGraph(graph.firstElementChild);
      FreezingGraphAxis(table);
    }

    const details = cells.map(dayCells => dayCellsDetail(dayCells));
    this.minDetail = Math.min(...details);

    let summaryCells = [];
    let summaryI = 0;
    let currentLength = 0;

    this.size = 0;
    this.days = cells.map(dayCells => {
      const size = dayCells.time.length;

      if (dayCells.summary) {
        summaryCells = summaryCells.concat(dayCells.summary);
        delete dayCells.summary;
      }

      let summaryCell = summaryCells[summaryI];
      if (summaryCell) {
        if (summaryCell.colSpan <= currentLength) {
          summaryI++;
          summaryCell = summaryCells[summaryI];
          currentLength = 0;
        }
        currentLength += size;
      }

      const date = dayCellsDate(dayCells);
      const detail = dayCellsDetail(dayCells);
      const day = new ForecastTableDay({
        table: this,
        date,
        minDetail: this.minDetail,
        summaryCell,
      });
      day.setCells(this.level, detail, dayCells);
      this.size += size;
      return day;
    });
  }

  dayOffset(day) {
    let offset = 0;
    for (let i = 0; i < this.days.length; i++) {
      if (this.days[i] === day) break;

      offset += this.days[i].size();
    }
    return offset;
  }

  todayOffsetLeft() {
    return this.days[this.todayIndex].header.root.offsetLeft;
  }

  detailChanged(day, oldSize, newSize, freezingData) {
    const delta = newSize - oldSize;
    this.size += delta;

    if (this.graph && freezingData) {
      const [groups, points] = freezingData;
      const start = this.dayOffset(day);
      const border = oldSize === 0 ? 1 : 0;
      const offset = delta * 38 + border;
      const end = start + oldSize;

      this.graphCell.colSpan = this.size;
      this.graph.updateSVGWidth(this.graph.width + offset);
      this.graph.updateCells(start, oldSize, groups)();
      this.graph.updateFreezingLine(start, oldSize, points)();

      if (oldSize === 0) {
        this.graph.addDayEndLine(offset);
      } else {
        this.graph.updateDayEndLines(end, delta)();
      }
    }
  }

  changeLevel(level) {
    this.level = level;
    return Promise.all(this.days.map(d => d.changeLevel(level)));
  }

  async expandAll() {
    await Promise.all(this.days.map(d => d.expand()));
  }

  async collapseAll() {
    await Promise.all(this.days.map(d => d.collapse()));
  }

  async back() {
    const firstDay = this.days[0];
    const needsCompletion = firstDay.isIncomplete();
    const detail = 0;

    const data = await this.data.fetchPast(
      this.level,
      firstDay.date,
      needsCompletion
    );
    if (!data.cells) return data;

    const { cells } = data;
    delete data.cells;

    for (let i = 0; i < cells.length; i++) {
      const dayCells = cells[i];

      // TODO: move completion logic to the backend and check date here instead
      if (needsCompletion && i === cells.length - 1) {
        if (firstDay.detail === detail) firstDay.updateCells(detail, dayCells);
        firstDay.setCells(this.level, detail, dayCells);
      } else {
        const day = new ForecastTableDay({
          table: this,
          date: dayCellsDate(dayCells),
          minDetail: detail,
        });
        this.days.splice(i, 0, day);

        day.insert(this.level, detail, dayCells, firstDay);
        this.todayIndex++;
      }
    }

    return data;
  }

  fetchExpansion(level, date, detail) {
    return this.data.fetchDay(level, detail, date);
  }
}
