/**
 * Creates a new line svg element
 * @param {Object} { x1, y1, x2, y2 }
 * @returns {SVGLineElement}
 */
function createLine({ x1, y1, x2, y2 }) {
  const line = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'polyline'
  );
  line.classList.add('grid-line');
  line.setAttribute('stroke', 'black');
  line.setAttribute('stroke-width', 1);
  line.setAttribute('fill', 'none');
  line.setAttribute(
    'points',
    [
      [x1, y1],
      [x2, y2],
    ]
      .map(pair => pair.join(','))
      .join(' ')
  );

  return line;
}

/**
 * An interface helps to manipulate .grid-line elements within an svg
 * @param {SVGSVGElement} svg
 * @returns {Object} api
 */
function GridLines(svg) {
  const gridLinesContainer = svg.querySelector('g.grid-lines');
  const gridLines = Array.from(
    gridLinesContainer.querySelectorAll('.grid-line')
  );

  const api = {
    /**
     * Checks if there is a line in a particular index.
     * @param {Number} i
     * @returns {Boolean}
     */
    has(i) {
      return Boolean(gridLines[i]);
    },
    get length() {
      return gridLines.length;
    },
    /**
     * Creates a new line, append it to the DOM, and pushes it to the array
     * @param {Object} settings
     * @param {Number} settings.x1
     * @param {Number} settings.x2
     * @param {Number} settings.y1
     * @param {Number} settings.y2
     * @returns {Function} side effect function
     */
    add(settings) {
      const line = createLine(settings);
      gridLinesContainer.appendChild(line);
      gridLines.push(line);
    },
    /**
     * Removes the line specified by index from the DOM and the array
     * @param {Number} i
     * @returns {Function} side effect function
     */
    remove(i) {
      gridLines[i].remove();
      gridLines.splice(i, 1);
    },
    /**
     * Expands the width of all lines updating their x value
     * @param {Number} newWidth - assumes it is the width of the svg
     * @returns {function} side effect function
     */
    updateWidth(newWidth) {
      for (let i = 0; i < gridLines.length; i += 1) {
        gridLines[i].points.getItem(1).x = newWidth;
      }
    },
    /**
     * Updates (mutates) the y value of the particular line specified by an index.
     * @param {Number} newYPosition- Y coord
     * @param {Number} i
     * @returns {Function} side effect function
     */
    updateYPosition(newYPosition, i) {
      gridLines[i].points.getItem(0).y = newYPosition;
      gridLines[i].points.getItem(1).y = newYPosition;
    },
  };

  return Object.freeze(api);
}

export default GridLines;
