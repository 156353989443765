import './index.scss';
import LiveConditions from './live-conditions';

import units from 'utils/units';
import clock from 'utils/clock';

export default function init(container, trigger) {
  const el = container.querySelector('.live-conditions');
  const liveConditions = new LiveConditions({ el, clock, units });

  const outsideClickListener = e => {
    if (
      el !== e.target &&
      !el.contains(e.target) &&
      trigger !== e.target &&
      !trigger.contains(e.target)
    ) {
      liveConditions.hide();
      document.removeEventListener('click', outsideClickListener);
    }
  };

  trigger.addEventListener('click', () => {
    liveConditions.show();
    document.addEventListener('click', outsideClickListener);
  });
}
