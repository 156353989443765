import SelectorPopup, { CLASS_NAME } from './selector';
import addListeners from './selector-listeners';
import update from './selector-update';

import { moveTo } from 'utils/popup';
import Units from 'utils/units';
import { h } from 'utils/simple-script';

import './static/preferences.svg';
import './static/preferences-white.svg';
import './static/sailboat.svg';
import './index.scss';

function globalClickOnce(predicat, callback) {
  document.body.addEventListener('click', function listener(event) {
    if (predicat(event.target)) {
      callback();
      document.body.removeEventListener('click', listener);
    }
  });
}

export default function() {
  [...document.body.querySelectorAll('.switch-units')].forEach(button => {
    let el, metric, imperial;
    const simple = button.dataset.simple !== undefined;
    const withoutKnots = button.dataset.withoutKnots !== undefined;
    if (simple) {
      const css = CLASS_NAME;

      const onChange = e => {
        const type = e.target.value;
        Units.change({ length: type, temperature: type, speed: type });
      };

      metric = h('input', { class: 'radio-button__input',
                            type: 'radio',
                            name: 'units',
                            value: 'Metric',
                            events: { change: onChange } });
      imperial = h('input', { class: 'radio-button__input',
                              type: 'radio',
                              name: 'units',
                              value: 'Imperial',
                              events: { change: onChange } });

      el =
        h('div', { class: css },
          h('form', { action: '', method: 'POST' },
            h('div', { class: `${css}__container` },
              h('label', { class: `${css}__control radio-button radio-button--simple` },
                metric,
                h('span', { class: 'radio-button__button' }),
                h('span', { class: 'radio-button__label' }, '°C, cm, km/h')),
              h('label', { class: `${css}__control radio-button radio-button--simple` },
                imperial,
                h('span', { class: 'radio-button__button' }),
                h('span', { class: 'radio-button__label' }, '°F, in, mph')
               ))));
    } else {
      el = SelectorPopup(withoutKnots);
      addListeners(el);
    }

    button.addEventListener('click', () => {
      /* update controls to the current state */
      if (simple) {
        metric.checked = Units.current.length === 'Metric';
        imperial.checked = Units.current.length === 'Imperial';
      } else {
        update(el, Units.current);
      }

      /*
        it may happen if a user clicks to the button twice
        or if a user tries to close popup using the same button
       */
      if (document.body.contains(el)) return;

      document.body.appendChild(el);

      /*
        it is an asynchronous function
        because it reads nodes geometry and then mutates DOM
      */
      moveTo(el, button, err => {
        if (err) {
          return;
        }

        /* if a user clicks anywhere except the popup it will remove the popup. */
        globalClickOnce(
          target => !target.closest(`.${CLASS_NAME}`),
          () => document.body.removeChild(el)
        );
      });
    });
  });
}
