export function isNumber(value) {
  return !Number.isNaN(Number(value));
}

export function camelCase(values) {
  return values.map(value => value[0].toUpperCase() + value.slice(1)).join('');
}

export function updateTextContent(textContent, node) {
  /* eslint-disable-next-line no-param-reassign, no-return-assign */
  return (node.textContent = textContent);
}

export function cUpdateTextContent(textContent) {
  return function sideEffect(node) {
    return updateTextContent(textContent, node);
  };
}

export function saveValue(units, value, node) {
  /* eslint-disable no-param-reassign */
  if (!Object.hasOwnProperty.call(node.dataset, 'initial'))
    node.dataset.initial = value;

  const key = `backup${units}`;
  if (!Object.hasOwnProperty.call(node.dataset, key)) node.dataset[key] = value;
  /* eslint-enable no-param-reassign */
}

export function useSavedValue(newUnits, node, use = 'textContent') {
  const key = `backup${newUnits}`;

  if (Object.hasOwnProperty.call(node.dataset, key)) {
    if (use === 'textContent') updateTextContent(node.dataset[key], node);
    else
      node[use] = node.dataset[key]; /* eslint-disable-line no-param-reassign */
    return true;
  }

  return false;
}

export function getFullName(units) {
  const values = [];

  /* eslint-disable-next-line no-restricted-syntax */
  for (const type in units)
    if (Object.hasOwnProperty.call(units, type)) values.push(type, units[type]);

  return camelCase(values);
}

export function replaceValuesInPhrase(node, text, settings) {
  const { units, convert, pattern } = settings;

  let result;
  /* eslint-disable-next-line no-cond-assign */
  while ((result = pattern.exec(text))) {
    if (isNumber(result[1]))
      /* eslint-disable-next-line no-param-reassign */
      text = text.replace(result[0], convert(result[1]) + units);
  }

  return updateTextContent(text, node);
}
