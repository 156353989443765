const { screen } = window;

const orientation =
  typeof screen === 'object' &&
  (screen.orientation || screen.msOrientation || screen.mozOrientation || null);

const ScreenOrientation = {
  type() {
    return orientation ? orientation.type : null;
  },

  lock(type) {
    if (!orientation) return null;

    const lock =
      orientation.lock ||
      screen.lockOrientation ||
      screen.mozLockOrientation ||
      screen.msLockOrientation;
    if (!lock) return null;

    return lock.call(orientation, type);
  },

  unlock() {
    if (!orientation) return null;

    const unlock =
      orientation.unlock ||
      screen.unlockOrientation ||
      screen.mozUnlockOrientation ||
      screen.msUnlockOrientation;
    if (!unlock) return null;

    return unlock.call(orientation, null);
  },
};

export default ScreenOrientation;
