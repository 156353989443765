import { BElement } from './belement'

export { BElement }

interface BaseComponentClass<T, C> {
  cssClass?: string
  tagName?: string
  new(root: Element | null, config: C): T
  for(config?: C, root?: Element): T
}

function dasherize(s: string) {
  return s.replace(/^([A-Z])|[\s._](\w)/g, (match, p1, p2) =>
    p2 ? `-${p2.toLowerCase()}` : p1.toLowerCase()
  )
}

const all: any[] = []

export abstract class BaseComponent<Config = undefined> {
  static tagName = 'div'
  static cssClass?: string
  cssClass: string
  root: HTMLElement

  abstract init(): void

  static for<C, T extends BaseComponent<C>>(
    this: BaseComponentClass<T, C>,
    config: C,
    root?: Element
  ) {
    let component = all.find(m => m instanceof this && m.root === root)
    if (!component) {
      component = new this(root ?? null, config)
      all.push(component)
    }
    return component
  }

  static init<C, T extends BaseComponent<C>>(
    this: BaseComponentClass<T, C>,
    parent: Element,
    config?: C
  ): T[] {
    const css = this.cssClass || dasherize(this.name)
    return Array.from(parent.querySelectorAll(`.${css}`)).map(el => this.for(config, el))
  }

  constructor(root: Element | null, readonly config: Config) {
    const klass = this.constructor as typeof BaseComponent
    this.cssClass = klass.cssClass || dasherize(klass.name)

    if (root) {
      this.root = root as HTMLElement
    } else {
      const el = document.createElement(klass.tagName)
      el.classList.add(this.cssClass)
      this.root = el
    }

    this.init()
  }

  elementClassName(name: string) {
    return `${this.cssClass}__${name}`
  }

  element(name: string): BElement | null {
    const css = this.elementClassName(name)
    const element = this.root.querySelector(`.${css}`)

    if (element == null) return null

    return new BElement(element, css)
  }

  elements(name: string, root?: HTMLElement): BElement[] {
    const parent = root || this.root
    const css = this.elementClassName(name)
    return Array.from(parent.querySelectorAll(`.${css}`)).map(
      (element) => new BElement(element, css)
    )
  }

  modifierClassName(name: string) {
    return `${this.cssClass}--${name}`
  }

  toggleModifier(name: string, force?: boolean) {
    const css = this.modifierClassName(name)
    this.root.classList.toggle(css, force)
  }

  appendChild<C, T extends BaseComponent<C>>(component: T) {
    this.root.appendChild(component.root)
  }

  remove() {
    this.root.remove()
  }
}
