export const findUpperSelector = (el, classname = 'is-hidden') => {
  if (!el) return null;
  if (el.classList.contains(classname)) return el;

  let parent = el.parentNode;
  while (parent) {
    if (parent.classList && parent.classList.contains(classname)) return parent;
    parent = parent.parentNode;
  }

  return null;
};

export const throttle = (fn, interval) => {
  let lastTime;

  return function throttled(...args) {
    const timeSinceLastExecution = Date.now() - lastTime;

    if (!lastTime || timeSinceLastExecution >= interval) {
      fn.apply(this, args);
      lastTime = Date.now();
    }
  };
};

export const debounce = (fn, interval) => {
  let timer;

  return function debounced(...args) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      fn.apply(this, args);
    }, interval);
  };
};
