import smoothScroll from './smooth-scroll';

function mouseUpListener(callback) {
  return function mouseup(event) {
    event.preventDefault();

    callback();
    this.removeEventListener('mouseup', mouseup);
  };
}

function mouseDownListener(callback) {
  let isPressed = false;

  return function mousedown(event) {
    event.preventDefault();
    isPressed = true;

    (function recurrent(step) {
      smoothScroll(callback, step).then(() => {
        if (isPressed) recurrent(step + 38);
      });
    })(114);

    document.addEventListener(
      'mouseup',
      mouseUpListener(() => {
        isPressed = false;
      })
    );
  };
}

export default mouseDownListener;
