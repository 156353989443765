import airbrake from 'utils/airbrake';
import 'components/icon';
import initTooltips from './tooltips';
import ForecastTableData from './data';
import ForecastTableScroll from './scroll';
import ForecastTableContent from './content';
import './forecast-table.scss';
import 'components/forecast-table/custom.scss';
import './static/icon-chevron-circle-left.svg';
import './static/icon-chevron-circle-right.svg';

require.context('./static', false, /.{1,}/);

export default function init() {
  initTooltips();

  const container = document.getElementById('forecast-table');
  if (!container) return;

  const table = container.querySelector('[data-content]');
  if (!table) return;

  const { dataUrl: url, level, levels } = window.FCGON;
  const data = new ForecastTableData(url, levels || [level], level);
  const content = new ForecastTableContent(table, data, level);
  new ForecastTableScroll(container, content);

  window.changeElevation = async (elev) => {
    await content.changeLevel(elev);
  };

  const expandAll = document.querySelectorAll('[data-table-expand-all]');
  Array.from(expandAll).forEach(button => {
    let loading = false;
    let expanded = false;
    button.addEventListener('click', async event => {
      event.preventDefault();
      if (loading) return;

      loading = true;
      button.classList.add('is-loading');

      try {
        await (expanded ? content.collapseAll() : content.expandAll());
        expanded = !expanded;
        // eslint-disable-next-line no-param-reassign
        button.textContent = button.dataset[expanded ? 'collapse' : 'expand'];
      } catch (e) {
        airbrake.notify(e);
      }

      button.classList.remove('is-loading');
      loading = false;
    });
  });
}
