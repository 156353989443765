class MeasurementMarker {
  constructor({ css, el }) {
    this.css = css;
    this.el = el;
  }

  toggle(show) {
    this.el.classList.toggle(this.css.markerInvisible, !show);
  }
}

export default MeasurementMarker;
