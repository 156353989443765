const SVG_TAGS = ['svg', 'circle', 'text', 'g', 'rect', 'polygon'];

export function h(tagName, ...children) {
  let el
  if (SVG_TAGS.indexOf(tagName) !== -1) {
    el = document.createElementNS('http://www.w3.org/2000/svg', tagName);
  } else {
    el = document.createElement(tagName);
  }

  for (let child of children) {
    if (typeof child === 'object' && child.constructor === Object) {
      for (const name in child) {
        const value = child[name];

        if (name === 'events') {
          for (const event in value) {
            el.addEventListener(event, value[event]);
          }
        } else {
          el.setAttribute(name, value);
        }
      }
      continue;
    }

    if (typeof child === 'string') {
      child = document.createTextNode(child);
    }

    el.appendChild(child)
  }

  return el;
}
