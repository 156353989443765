import Units from 'utils/units'; // eslint-disable-line import/no-named-as-default

const classnames = {
  container: 'js-two-units-length-value',
  primary: 'js-two-units-length-value__primary',
  secondary: 'js-two-units-length-value__secondary',
};

const switchValues = units => {
  if (!units) return;

  const elements = document.getElementsByClassName(classnames.container);

  [...elements].forEach(el => {
    if (units === el.dataset.units) return;

    /* eslint-disable-next-line no-param-reassign */
    el.dataset.units = units;
    const primary = el.querySelector(`.${classnames.primary}`);
    const secondary = el.querySelector(`.${classnames.secondary}`);

    const secondaryText = secondary.innerText;

    secondary.innerText = `(${primary.innerText})`;
    primary.innerText = secondaryText.slice(1, -1);
  });
};

export default function init() {
  Units.onChange(changes => switchValues(changes.length));
}
