import icon from './icon-fullscreen-exit.svg';

const CloseButton = () => {
  const button = document.createElement('button');
  button.className =
    'button has-icon is-small is-white fullscreen-close-button';
  const img = document.createElement('img');
  img.src = icon;
  button.appendChild(img);

  let handlers = [];

  const api = {
    get() {
      return button;
    },
    append(parent) {
      parent.appendChild(button);

      return api;
    },
    remove() {
      handlers.forEach(x => button.removeEventListener(x.type, x.cb));
      handlers = [];

      button.parentNode.removeChild(button);

      return api;
    },
    setHandler(type, cb) {
      handlers.push({ type, cb });
      button.addEventListener(type, cb);

      return api;
    },
  };

  return Object.freeze(api);
};

export default CloseButton;
