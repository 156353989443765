/* eslint-disable no-console, no-param-reassign */
import { Notifier } from '@airbrake/browser';

const host = process.env.AIRBRAKE_FRONTEND_HOST || 'https://api.airbrake.io';
const projectId = parseInt(process.env.AIRBRAKE_FRONTEND_PROJECT_ID, 10);
const projectKey = process.env.AIRBRAKE_FRONTEND_PROJECT_KEY;
const onError = process.env.AIRBRAKE_FRONTEND_ONERROR === '1';

function createNotifier() {
  const notifier = new Notifier({
    host,
    projectId,
    projectKey,
    environment: process.env.RAILS_ENV,
    instrumentation: {
      onerror: onError,
      // we're at the limit of airbrake performance events anyway:
      fetch: false,
      xhr: false,
      unhandledrejection: false,
    },
  });

  if (process.env.AIRBRAKE_FRONTEND_FILTER_ERRORS !== '0') {
    const allErrors = window.location.href.indexOf('errors=all') !== -1;

    notifier.addFilter(notice => {
      const [{ backtrace }] = notice.errors;

      if (allErrors) {
        notice.params.allErrors = true;
      } else if (
        !backtrace.find(
          b =>
            b.file &&
            (b.file.indexOf('tide-forecast.com/packs') !== -1 ||
              b.file.indexOf('tide-forecast.com/assets') !== -1)
        ) ||
        backtrace.find(
          b =>
            b.file &&
            (b.file.indexOf('detroitchicago/') !== -1 ||
              b.file.indexOf('porpoiseant/') !== -1 ||
              b.file.indexOf('cdn.cookielaw.org') !== -1 ||
              b.file.indexOf('c.amazon-adsystem.com') !== -1)
        )
      ) {
        return null;
      }

      notice.params.origBacktrace = backtrace;

      return notice;
    });
  }

  return notifier;
}

const airbrake = projectKey
  ? createNotifier()
  : { notify: e => console.error(e) };

export default airbrake;
