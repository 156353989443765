import { BaseComponent, BElement } from '../../utils/base-component'
import units from '../../utils/units'
import './index.scss'

export class SnowAmount extends BaseComponent {
  static cssClass = 'snow-amount'

  value?: number
  text!: BElement

  init() {
    this.text = this.element('value')!

    const value = parseFloat(this.root.dataset.value!)
    this.value = Number.isNaN(value) ? undefined : value

    this.render()
    units.onChange(() => this.render())
  }

  render() {
    if (!this.text) return

    this.text.setHTML(this.value ? units.formatSnow(this.value) : '—')
  }
}
