/// <reference types="webpack-env" />
import units from '../../utils/units'
import { BaseComponent } from '../../utils/base-component'
import ArrowCircle from '../arrow-circle'
import './index.scss'

require.context('./static', true, /\.png$/)

type Config = {
  precision?: number
  size?: number
}

function createSVGElement(tag: string) {
  const el = document.createElementNS('http://www.w3.org/2000/svg', tag)
  if (!el) throw new Error('Could not create an SVG element')
  return el
}

// wind icons helpers for old code
const LETTERS = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S',
                 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW']

function parseWindSymbol(symbol: string) {
  const match = symbol.match(/([NEWS]+)(\d+)/)
  if (!match) return

  const index = LETTERS.indexOf(match[1])
  if (index === -1) return

  let angle = index * 360.0 / LETTERS.length + 180
  if (angle >= 360) angle -= 360

  return { speed: match[2], angle: `${angle}` }
}

export class WindIcon extends BaseComponent<Config> {
  static cssClass = 'wind-icon'

  svg!: SVGElement
  circle!: SVGElement
  arrow!: ArrowCircle
  textEl!: SVGElement
  speed?: number
  precision!: number
  size!: number

  static parse(symbol: string, root?: HTMLElement): WindIcon | undefined {
    const wind = parseWindSymbol(symbol)
    if (!wind) return

    const icon = WindIcon.for({}, root)
    icon.update(wind)
    return icon
  }

  init() {
    if (this.config) {
      this.precision = this.config.precision || 5
      this.size = this.config.size || 38
    } else {
      this.precision = parseInt(this.root.dataset?.precision ?? '5')
      this.size = 38
    }

    let svg = this.root.querySelector('svg') as SVGElement
    if (!svg) {
      svg = createSVGElement('svg')
      svg.setAttribute('viewBox', `${- this.size / 2} ${- this.size / 2} ${this.size} ${this.size}`)
      svg.setAttribute('fill', 'rgb(0, 0, 0)')
      svg.setAttribute('class', this.elementClassName('svg'))
      this.root.appendChild(svg)
    }
    this.svg = svg

    let circle = this.root.querySelector('circle') as SVGElement
    if (!circle) {
      circle = createSVGElement('circle')
      circle.setAttribute('cx', '0')
      circle.setAttribute('cy', '0')
      svg.appendChild(circle)
    }
    this.circle = circle

    let arrow = this.element('arrow')
    if (arrow) {
      this.arrow = new ArrowCircle(arrow.element)
    } else {
      this.arrow = new ArrowCircle()
      this.arrow.el.setAttribute('class', this.elementClassName('arrow'))
      svg.appendChild(this.arrow.el)
    }

    let text = this.element('val')?.element as Element as SVGElement
    if (!text) {
      text = createSVGElement('text')
      text.setAttribute('class', this.elementClassName('val'))
      text.setAttribute('x', '0')
      text.setAttribute('y', '5')
      text.setAttribute('text-anchor', 'middle')
      svg.appendChild(text)
    }
    this.textEl = text

    const { speed } = this.root.dataset
    if (speed) {
      this.setSpeed(speed)
      this.renderSpeed()
    }

    units.onChange(() => this.renderSpeed())
  }

  setSpeed(speed: string) {
    const s = parseFloat(speed)
    this.speed = Number.isNaN(s) ? undefined : s
  }

  renderSpeed() {
    this.textEl.textContent = this.speed !== null
      ? units.formatSpeed(this.speed, { precision: this.precision })
      : '-'
  }

  // TODO: replace update with setWind everywhere
  setWind({ speed, angle }: { speed: number, angle: number }) {
    this.update({ speed: speed.toString(), angle: angle.toString() })
  }

  update({ speed, angle }: { speed: string, angle: string }) {
    const speedf = parseFloat(speed)
    const radius = Number.isNaN(speedf) || speedf <= 100 ? 10.5 : 12.5
    const attrs = {
      arrowheadThickness: 0,
      arrowheadHeight: 6,
      connectorThickness: 4,
      connectorHeight: 0,
      radius,
      angle,
    }

    this.circle.setAttribute('r', `${radius}`)

    if (angle !== undefined) {
      if (speedf <= 100) {
        attrs.arrowheadThickness = 14
        attrs.connectorHeight = 4
      } else {
        attrs.arrowheadThickness = 18
        attrs.connectorHeight = 2.5
      }
    }

    this.arrow.update(attrs)

    if (speed !== undefined) {
      this.setSpeed(speed)
      this.renderSpeed()
      this.svg.setAttribute('fill', this.circleColor())
      this.textEl.setAttribute('fill', this.textColor())
    }
  }

  textColor() {
    if (!this.speed || this.speed < 20) return 'rgb(255, 255, 255)'
    if (this.speed < 40) return 'rgb(0, 255, 0)'
    if (this.speed < 60) return 'rgb(255, 255, 0)'
    if (this.speed < 80) return 'rgb(255, 180, 0)'
    if (this.speed < 100) return 'rgb(255, 0, 0)'

    return 'rgb(255, 255, 255)'
  }

  circleColor() {
    if (!this.speed || this.speed < 100) return 'rgb(0, 0, 0)'

    return 'rgb(255, 0, 0)'
  }
}
