import hardtack from 'hardtack';

import polyfill from './polyfill';
import {
  saveValue,
  getFullName,
  useSavedValue,
  updateTextContent,
  cUpdateTextContent,
  replaceValuesInPhrase,
} from './helpers';
import {
  convertWindImageUrlToImperial,
  convertSwellImageUrlToImperial,
} from './convert-value-images';

import Units from 'utils/units'; // eslint-disable-line import/no-named-as-default
import {
  convertKphToMph,
  convertKphToKnot,
  convertCmToInches,
  convertCelsiusToFahrenheit,
  convertMetersToFeet,
  convertKilometersToMiles,
  convertMillimetersToInches,
} from 'utils/units/helpers';

const currentUnits = {
  temperature: 'Metric',
  length: 'Metric',
  speed: 'Metric',
};

const unitsConversion = [
  {
    type: 'temperature',
    selector: 'span.temp',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(
          convertCelsiusToFahrenheit(value, { round: true }),
          node
        ),
    },
  },
  {
    type: 'temperature',
    selector: 'span.tempu',
    conversion: {
      Imperial: cUpdateTextContent('F'),
    },
  },
  {
    type: 'height',
    selector: 'span.height',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(convertMetersToFeet(value, { round: true }), node),
    },
  },
  {
    type: 'height',
    selector: 'span.heightfl, span.heighttide',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(convertMetersToFeet(value, { precision: 2 }), node),
    },
  },
  {
    type: 'height',
    selector: 'span.heightu',
    conversion: {
      Imperial: cUpdateTextContent('ft'),
    },
  },
  {
    type: 'distance',
    selector: 'span.dist',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(
          convertKilometersToMiles(value, {
            roundBy: 0.1,
            toFixed: 1,
          }),
          node
        ),
    },
  },
  {
    type: 'distance',
    selector: 'span.distu',
    conversion: {
      Imperial: cUpdateTextContent('miles'),
    },
  },
  {
    type: 'snow',
    selector: 'span.snow',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(convertCmToInches(value, { precision: 1 }), node),
    },
  },
  {
    type: 'snow',
    selector: 'span.snowht',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(convertCmToInches(value, { round: true }), node),
    },
  },
  {
    type: 'snow',
    selector: 'span.snowu',
    conversion: {
      Imperial: cUpdateTextContent('in'),
    },
  },
  {
    type: 'wind',
    selector: 'span.wind',
    conversion: {
      Imperial: node =>
        updateTextContent(
          convertKphToMph(node.dataset.initial, { roundBy: 5 }),
          node
        ),
      Knot: node =>
        updateTextContent(
          convertKphToKnot(node.dataset.initial, { roundBy: 5 }),
          node
        ),
    },
  },
  {
    type: 'wind',
    selector: 'span.windu',
    conversion: {
      Imperial: cUpdateTextContent('mph'),
      Knot: cUpdateTextContent('knots'),
    },
  },
  {
    type: 'rain',
    selector: 'span.rain',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(
          convertMillimetersToInches(value, {
            toFixed: 1,
          }),
          node
        ),
    },
  },
  {
    type: 'rain',
    selector: 'span.rainu',
    conversion: {
      Imperial: cUpdateTextContent('in'),
    },
  },
];

function convertNodes(cUnits, nUnits, { container, els, type }) {
  const selectors = els.map(el => el.selector);
  const nodes = container.querySelectorAll(selectors.join(', '));

  /* eslint-disable no-plusplus, no-continue */
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const value = node.textContent;
    if (value === '' || value === '-') continue;

    saveValue(cUnits, value, node);
    if (useSavedValue(nUnits, node)) continue;

    for (let j = 0; j < els.length; j++) {
      const el = els[j];

      if (node.matches(el.selector)) {
        const conversion = el.conversion[nUnits];
        if (conversion) conversion(node, value, type);
        break;
      }
    }
  }
}

const phraseConversion = {
  temperature(node, value, units) {
    if (units === 'Imperial')
      /* C -> F */
      return replaceValuesInPhrase(node, value, {
        pattern: /([-\d.]+)(°|&deg;)C/g,
        convert(num) {
          return convertCelsiusToFahrenheit(num, { round: true });
        },
        units: '°F',
      });

    return value;
  },
  length(node, value, units) {
    /* eslint-disable no-param-reassign */
    if (units === 'Imperial') {
      /* mm -> in */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)mm/g,
        convert(num) {
          return convertMillimetersToInches(num, { precision: 1 });
        },
        units: 'in',
      });

      /* km -> mi */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+) km/g,
        convert(num) {
          return convertKilometersToMiles(num, { round: true });
        },
        units: ' miles',
      });

      /* m -> ft */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)( ?)m/g,
        convert(num) {
          return convertMetersToFeet(num, { round: true });
        },
        units: ' ft',
      });

      /* cm -> in */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)cm/g,
        convert(num) {
          return convertCmToInches(num, { precision: 1 });
        },
        units: ' in',
      });
    }
    /* eslint-enable no-param-reassign */

    return value;
  },
  speed(node, value, units) {
    if (units === 'Imperial')
      /* km/h -> mph */
      return replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)( ?)km\/h/g,
        convert(num) {
          return convertKphToMph(num, { round: true });
        },
        units: 'mph',
      });

    if (units === 'Knot')
      /* km/h -> knots */
      return replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)( ?)km\/h/g,
        convert(num) {
          return convertKphToKnot(num, { round: true });
        },
        units: 'knots',
      });

    return value;
  },
};

function convertPhrases(cUnits, nUnits, { container }) {
  const nodes = container.querySelectorAll('span.phrase');

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const { textContent } = node;
    if (textContent === '' || textContent === '-') continue;

    saveValue(getFullName(cUnits), textContent, node);
    if (useSavedValue(getFullName(nUnits), node)) continue;

    let value = node.dataset.initial;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const type in cUnits)
      if (Object.hasOwnProperty.call(nUnits, type))
        value = phraseConversion[type](node, value, nUnits[type]);
      else value = phraseConversion[type](node, value, cUnits[type]);
  }
  /* eslint-enable no-plusplus, no-continue */
}

function convertImagesOld(className, convert, cUnits, nUnits) {
  const imgs = document.getElementsByClassName(className);
  Array.prototype.forEach.call(imgs, img => {
    saveValue(cUnits, img.src, img);
    if (useSavedValue(nUnits, img, 'src')) return;

    const newSrc = convert(img.src);
    if (newSrc === null) return;
    img.src = newSrc; /* eslint-disable-line no-param-reassign */
  });
}

const compatMap = {
  Knot: 'knt',
  Metric: 'met',
  Imperial: 'imp',
  temperature: 'temp',
  length: 'len',
  speed: 'spd',
};

function reverseObj(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    acc[obj[key]] = key;
    return acc;
  }, {});
}

function setCookie(units) {
  const fcUnits = Object.keys(units)
    .map(unitType => `${compatMap[unitType]}.${compatMap[units[unitType]]}`)
    .join('_');

  hardtack.set('_fcunits', fcUnits, {
    path: '/',
    maxAge: 60 * 60 * 24 * 365,
  });
}

const typesMap = {
  speed: ['wind'],
  temperature: ['temperature'],
  length: ['height', 'distance', 'snow', 'rain'],
};

function convertLengthUnits(from, to, container) {
  convertNodes(from, to, {
    container,
    els: unitsConversion.filter(el =>
      typesMap.length.some(type => type === el.type)
    ),
  });
}

function convertUnits(newUnits, { container }) {
  if (currentUnits.temperature !== newUnits.temperature)
    convertNodes(currentUnits.temperature, newUnits.temperature, {
      container,
      els: unitsConversion.filter(el =>
        typesMap.temperature.some(type => type === el.type)
      ),
      type: 'temperature',
    });

  if (currentUnits.length !== newUnits.length) {
    convertLengthUnits(currentUnits.length, newUnits.length, container);

    /* legacy */
    convertImagesOld(
      'swellimg',
      convertSwellImageUrlToImperial,
      currentUnits.length,
      newUnits.length
    );
  }

  if (currentUnits.speed !== newUnits.speed) {
    convertNodes(currentUnits.speed, newUnits.speed, {
      container,
      els: unitsConversion.filter(el =>
        typesMap.speed.some(type => type === el.type)
      ),
    });

    /* legacy */
    if (currentUnits.speed !== 'Knot' && newUnits.speed !== 'Knot')
      convertImagesOld(
        'windimg',
        convertWindImageUrlToImperial,
        currentUnits.speed,
        newUnits.speed
      );
  }

  convertPhrases(currentUnits, newUnits, { container });

  currentUnits.temperature = newUnits.temperature;
  currentUnits.length = newUnits.length;
  currentUnits.speed = newUnits.speed;

  return true;
}

window.currentUnits = currentUnits;
window.convertUnits = convertUnits;
window.convertLengthUnits = convertLengthUnits;

export default function (preferUnits) {
  polyfill();

  const reversedCompatMap = reverseObj(compatMap);
  const fcunits = (hardtack.get('_fcunits') || '')
    .split('_')
    .reduce((acc, pair) => {
      const [unitType, value] = pair.split('.');
      if (unitType && value)
        acc[reversedCompatMap[unitType]] = reversedCompatMap[value];
      return acc;
    }, {});

  const newUnits = {};
  Object.keys(currentUnits).forEach(type => {
    newUnits[type] = fcunits[type] || preferUnits;
  });

  convertUnits(newUnits, { container: document.body });
  Units.change(newUnits);

  Units.onChange(changes => {
    convertUnits({ ...currentUnits, ...changes }, { container: document.body });
    setCookie(currentUnits);
  });
}
