import { findUpperSelector } from 'components/utils';

import './index.scss';

const moveContent = ({ height, shouldMoveBack }) => {
  const classes = ['main', 'header', 'footer'];

  classes.forEach(e => {
    const el = document.querySelector(`.${e}`);
    if (el) {
      if (!shouldMoveBack) el.style = `transform: translateY(${height}px);`;
      else el.style = 'transform: translateY(0);';
    }
  });
};

const switchClass = el => {
  const classname = 'is-showed';

  if (el.classList.contains(classname)) {
    moveContent({ shouldMoveBack: true });
    el.classList.remove(classname);
  } else {
    moveContent({ height: el.offsetHeight });
    el.classList.add(classname);
    el.querySelector('input[type=text]').focus();
  }
};

const setRemoveHandler = el => {
  const classname = 'is-showed';

  if (el.classList.contains(classname)) return switchClass(el);

  const handler = event => {
    if (
      event.target !== el &&
      !findUpperSelector(event.target, 'header-form--js')
    ) {
      switchClass(el);
      el.blur();
      document.removeEventListener('click', handler);
    }
  };

  setTimeout(() => {
    document.addEventListener('click', handler);
  }, 0);

  return true;
};

window.addEventListener('load', () => {
  const search = document.getElementById('header-form-search');
  const mobileHomepageSearch = document.getElementById(
    'header-form-search-homepage-mobile'
  );
  const button = document.getElementById('header-form-button');

  if (button)
    button.addEventListener('click', () => {
      if (mobileHomepageSearch) {
        mobileHomepageSearch.querySelector('input[type=text]').focus();
      } else if (search) {
        setRemoveHandler(search);
        switchClass(search);
      } else {
        const searchAlternative = document.getElementById(
          'content-form-search'
        );
        if (searchAlternative)
          searchAlternative.querySelector('input[type=text]').focus();
      }
    });
});
