import units from '../../../utils/units'
import GridLines from './grid-lines';
import Cells from './cells';
import Polyline from './polyline';
import DayEndLines from './day-end-lines';

/**
 * Represents an interface (API) helps to manipulate the Freezing Chart
 * Based on an idea that every side effect methods prepare and return small, fast, and dirty function
 * @param {HTMLTableCellElement} container - an element containing the Freezing Chart
 * @returns {Object} api
 */
function ForecastFreezingGraph(container) {
  const outerDiv = container;
  const innerDiv = outerDiv.firstElementChild;
  const svg = innerDiv.querySelector('svg');
  const elevLine = svg.querySelector('.current-elevation')

  const gridLines = GridLines(svg);
  const cells = Cells(svg);
  const polyline = Polyline(svg);
  const dayEndLines = DayEndLines(svg);

  let width = svg.viewBox.baseVal.width;

  const api = {
    get width() {
      return width;
    },
    get cells() {
      return cells.cells;
    },
    /**
     * Updates (mutates) the svg viewBox attribute and the grid lines x value
     * @param {Number} newWidth
     * @returns {Function} side effect function
     */
    updateSVGWidth(newWidth) {
      width = newWidth;
      outerDiv.style.width = `${newWidth + 1}px`;
      svg.viewBox.baseVal.width = newWidth;
      gridLines.updateWidth(newWidth);

      if (elevLine) {
        elevLine.points.getItem(1).x = newWidth;
      }
    },
    /**
     * Deletes a particular amount of elements and adds new cells to the start point.
     * Updates cells positionating.
     * @param {Number} start - an index
     * @param {Number} deleteCount - amount of cells to delete
     * @param {Array.<SVGGElement>} items - a list of new cells
     * @returns {Function} a side effect mutate function to do the splice
     */
    updateCells(start, deleteCount, items) {
      return cells.splice(start, deleteCount, items);
    },
    /**
     * Deletes a particular amount of elements and adds new points to the start point.
     * Updates points positionating.
     * @param {Number} start - an index
     * @param {Number} deleteCount - amount of points to delete
     * @param {Array.<Array.<Number>>} items - a list of new points
     * @returns {Function} a side effect mutate function to do the splice
     */
    updateFreezingLine(start, deleteCount, items) {
      return polyline.splice(start, deleteCount, items);
    },
    /**
     * Finds the current day end line by position and shifts it and all lines following by it to the offset level.
     * @param {Number} position - a value from data-group-start attribute it matches particular line
     * @param {Number} offset - the offset level to which it shifts the lines.
     * @returns {Function} a side effect mutate function to do the shift
     */
    updateDayEndLines(linePosition, offset) {
      return dayEndLines.shift(linePosition, offset);
    },

    addDayEndLine(offset) {
      return dayEndLines.add(offset);
    },
    /**
     * Updates, creates, or removes the grid lines
     * based on the difference between amount of Y positions and amount of grid lines
     * @param {Number[]} newYPositions
     * @returns {Function} side effect function
     */
    updateGridLines(newYPositions) {
      const maxLength = Math.max(newYPositions.length, gridLines.length);

      for (let i = 0; i < maxLength; i++) {
        const newY = newYPositions[i];

        if (gridLines.has(i)) {
          if (newY !== undefined) {
            gridLines.updateYPosition(newY, i);
          } else {
            gridLines.remove(i);
            // TODO: removing an item shifts indices
            // it's not obvious but it's safe to do because there are no more newYs
            i -= 1
          }
        } else if (newY !== undefined) {
          gridLines.add({ x1: 0, x2: width, y1: newY, y2: newY });
        }
      }
    },
  };

  const switchUnits = (changes) => {
    if (!changes.length) return;

    const unitsInfo = window.flgraphUnitsInfo[changes.length];
    api.updateGridLines(unitsInfo.grid.y);
  };
  units.onChange(switchUnits);
  switchUnits(units.current);

  return Object.freeze(api);
}

export default ForecastFreezingGraph;
