import { timestampsDiff, to12HrFmt } from 'components/utils/time';

class Countdowns {
  constructor({ css, el, clock }) {
    this.css = css;
    this.el = el;
    this.clock = clock;

    this.container = el.querySelector(`.${css.container}`);
    this.nextHighCountdown = el.querySelector(`.${css.nextHighCountdown}`);
    this.nextLowCountdown = el.querySelector(`.${css.nextLowCountdown}`);
    this.nextHighTime = el.querySelector(`.${css.nextHighTime}`);
    this.nextLowTime = el.querySelector(`.${css.nextLowTime}`);
    this.localtime = el.querySelector(`.${css.localtimeValue}`);
    this.localtimeContainer = el.querySelector(`.${css.localtime}`);

    this.container.classList.remove(css.containerInvisible);
    this.localtimeContainer.classList.remove(css.localtimeInvisible);
  }

  update({ timestamp, nextHighTide, nextLowTide }) {
    const highDelta = timestampsDiff(timestamp, nextHighTide.timestamp, true);
    this.nextHighCountdown.innerHTML = highDelta;
    this.nextHighTime.innerHTML = nextHighTide.time;

    const lowDelta = timestampsDiff(timestamp, nextLowTide.timestamp, true);
    this.nextLowCountdown.innerHTML = lowDelta;
    this.nextLowTime.innerHTML = nextLowTide.time;

    const localDate = this.clock.timestampToLocal(timestamp);
    this.localtime.innerHTML = to12HrFmt(localDate);
  }
}

export default Countdowns;
