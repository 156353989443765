import './index.scss';
import Arrow from './arrow';

import clock from 'utils/clock';
import { Tides } from 'utils/tide/tides';

export default function () {
  const el = document.querySelector('.mini-tide');
  if (!el) return false;

  const tides = new Tides({ clock });
  tides.fromDays(window.FCGON.tideDays);

  const arrow = new Arrow({ el, tides });

  const update = () => {
    arrow.update(clock.currentTimestamp());
    setTimeout(update, 60000);
  };

  el.classList.remove('mini-tide--invisible');
  update();

  return true;
}
