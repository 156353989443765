import throttle from 'utils/throttle';
import debounce from 'utils/debounce';

import readBoundaries from './read-boundaries';
import toggleClasses from './toggle-classes';

function hasScrollSpaceOnRight(offsetWidth, scrollWidth, scrollLeft) {
  return scrollWidth - offsetWidth - scrollLeft !== 0;
}

function hasScrollSpaceOnLeft(scrollLeft) {
  return scrollLeft > 20;
}

function scrollListener(el, container) {
  const handler = throttle(() => {
    readBoundaries(container, (offsetWidth, scrollWidth, scrollLeft) => {
      toggleClasses(
        hasScrollSpaceOnRight.bind(null, offsetWidth, scrollWidth, scrollLeft),
        hasScrollSpaceOnLeft.bind(null, scrollLeft),
        el
      );
    });
  }, 500);
  container.addEventListener('scroll', handler);

  return handler;
}

function mutationListener(el, container) {
  const observer = new MutationObserver(
    throttle(() => {
      readBoundaries(container, (offsetWidth, scrollWidth, scrollLeft) => {
        toggleClasses(
          hasScrollSpaceOnRight.bind(
            null,
            offsetWidth,
            scrollWidth,
            scrollLeft
          ),
          hasScrollSpaceOnLeft.bind(null, scrollLeft),
          el
        );
      });
    }, 500)
  );
  observer.observe(container, { childList: true, subtree: true });

  return observer;
}

function resizeListener(el, container) {
  const handler = debounce(() => {
    readBoundaries(container, (offsetWidth, scrollWidth, scrollLeft) => {
      toggleClasses(
        hasScrollSpaceOnRight.bind(null, offsetWidth, scrollWidth, scrollLeft),
        hasScrollSpaceOnLeft.bind(null, scrollLeft),
        el
      );
    });
  }, 1000);
  window.addEventListener('resize', handler);

  return handler;
}

function ScrollFade(el) {
  const container = el.querySelector('[data-scroll-container]');
  if (!container) return false;

  let scrollHandler = scrollListener(el, container);
  let resizeHandler = resizeListener(el, container);
  let mutationHandler = mutationListener(el, container);

  readBoundaries(container, (offsetWidth, scrollWidth, scrollLeft) => {
    toggleClasses(
      hasScrollSpaceOnRight.bind(null, offsetWidth, scrollWidth, scrollLeft),
      hasScrollSpaceOnLeft.bind(null, scrollLeft),
      el,
      () => {
        scrollHandler = scrollListener(el, container);
        resizeHandler = resizeListener(el, container);
        mutationHandler = mutationListener(el, container);
      }
    );
  });

  const api = {
    disable() {
      container.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', resizeHandler);
      mutationHandler.disconnect();
    },
  };

  return Object.freeze(api);
}

export default ScrollFade;
