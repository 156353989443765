import { CELL_WIDTH } from './constants';

/**
 * An interface helps to manipulate .day-end-line elements within an svg
 * @param {SVGSVGElement} svg
 * @returns {Object} API
 */
function DayEndLines(svg) {
  const container = svg.querySelector('.day-end-lines');

  const lines = Array.from(container.querySelectorAll('.day-end-line')).map(
    line => {
      const { groupPosition } = line.dataset;

      return [Number(groupPosition), line];
    }
  );

  const api = {
    add(offset) {
      const cellsOffset = Math.floor(offset / CELL_WIDTH);

      lines.forEach(line => {
        const x1 = Number(line[1].getAttribute('x1'));
        const x2 = Number(line[1].getAttribute('x2'));

        line[1].setAttribute('x1', x1 + offset);
        line[1].setAttribute('x2', x2 + offset);
        line[0] += cellsOffset; /* eslint-disable-line no-param-reassign */
      });

      const firstLine = lines[0][1];
      const line = firstLine.cloneNode(true);
      line.setAttribute('x1', offset - 0.5);
      line.setAttribute('x2', offset - 0.5);
      container.appendChild(line);
      lines.unshift([cellsOffset, line]);
    },

    /**
     * Finds the current day end line by position and shifts it and all lines following by it to the offset level.
     * @param {Number} position - a value from data-group-start attribute it matches particular line
     * @param {Number} offset - the offset level to which it shifts the lines.
     * @returns {Function} a side effect mutate function to do the shift
     */
    shift(linePosition, offset) {
      const fns = [];
      const startIdx = lines.findIndex(
        ([position]) => position === linePosition
      );

      const d = offset > 0 ? 0.5 : -0.5;

      lines.slice(startIdx).forEach(line => {
        const x1 = Number(line[1].getAttribute('x1'));
        const x2 = Number(line[1].getAttribute('x2'));

        fns.push(() => {
          line[1].setAttribute('x1', x1 + offset * window.parseInt(CELL_WIDTH) + d);
          line[1].setAttribute('x2', x2 + offset * window.parseInt(CELL_WIDTH) + d);
          line[0] += offset; /* eslint-disable-line no-param-reassign */
        });
      });

      return function run() {
        fns.forEach(fn => fn());
      };
    },
  };

  return Object.freeze(api);
}

export default DayEndLines;
