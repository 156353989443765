export default function parseHtml(html) {
  let doc = new DOMParser().parseFromString(html, 'text/html');

  if (!doc) {
    doc = document.implementation.createHTMLDocument('');
    doc.body.innerHTML = html;
  }

  return doc;
}
