// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
function CustomEventImplementation() {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    const evt = document.createEvent('CustomEvent');

    params = params || { bubbles: false, cancelable: false, detail: undefined }; // eslint-disable-line no-param-reassign
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
}

function numberIsNaN() {
  if (Object.hasOwnProperty.call(Number, 'isNaN')) return;

  Number.isNaN = function isNaN(value) {
    /* eslint-disable-next-line no-restricted-properties */
    return typeof value === 'number' && window.isNaN(value);
  };
}

export default function () {
  CustomEventImplementation();
  numberIsNaN();
}
