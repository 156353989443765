import MeasurementMarker from './measurement-marker';

class NextLowOrHighTideMarker extends MeasurementMarker {
  constructor({ css, el, units }) {
    super({ css, el });
    this.units = units;

    this.heightEl = el.querySelector(`.${css.markerHeight}`);
    this.timeEl = el.querySelector(`.${css.markerTime}`);
    this.units.onChange(() => this.renderHeight());
  }

  renderHeight() {
    const height = this.units.formatLength(this.tide.height);
    this.heightEl.innerText = `${height}${this.units.formatLengthUnits()}`;
  }

  update(tide) {
    this.tide = tide;
    this.timeEl.innerText = tide.time;
    this.renderHeight();
  }

  toggleTransparent(transparent) {
    this.el.classList.toggle(this.css.markerTransparent, transparent);
  }
}

export default NextLowOrHighTideMarker;
