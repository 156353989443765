import throttle from 'utils/throttle';
// import { showSignup } from 'components/signup';

const css = 'forecast-table';
const buttonCss = `${css}__scroll-button`;

export default class ForecastTableScroll {
  constructor(el, table) {
    this.hiddenCss = `${buttonCss}--hidden`;
    this.releaseCss = `${buttonCss}--release`;
    this.loadingCss = `${buttonCss}--loading`;
    this.loading = false;
    this.table = table;
    this.hasPast = el.dataset.hasPast !== undefined;
    this.keepScrollingLeft = false;
    this.keepScrollingRight = false;

    this.leftLabels = el.parentNode.querySelectorAll(
      '.hindcast-prompt__title--left'
    );
    this.leftIcons = el.parentNode.querySelectorAll(
      '.hindcast-prompt__chevron-left'
    );
    this.leftLabels.forEach(icon => {
      icon.addEventListener('click', e => {
        e.preventDefault();
        this.scrollLeft({ step: 115 * 7, once: true });
      });
    });

    const rightLabels = el.parentNode.querySelectorAll(
      '.hindcast-prompt__title--right'
    );
    rightLabels.forEach(icon => {
      icon.addEventListener('click', e => {
        e.preventDefault();
        this.scrollToToday();
      });
    });

    this.root = el.querySelector(`.${css}__content`);
    this.root.addEventListener('touchstart', e => this.touchStarted(e));
    this.root.addEventListener('touchmove', e => this.touchMoved(e));
    this.root.addEventListener('touchend', () => this.touchEnded());

    this.updateFn = throttle(() => this.update(), 500);
    this.scrollLeftFn = () => this.scrollLeft();
    this.scrollRightFn = () => this.scrollRight();
    this.observer = new MutationObserver(this.updateFn);

    this.buttonLeft = el.querySelector(`.${css}__scroll-button--left`);
    if (!this.buttonLeft) this.buttonLeft = document.createElement('button');
    this.buttonLeft.addEventListener('mouseup', () => this.stopScrollingLeft());
    this.buttonLeft.addEventListener('mouseleave', () =>
      this.stopScrollingLeft()
    );

    this.buttonRight = el.querySelector(`.${css}__scroll-button--right`);
    if (!this.buttonRight) this.buttonRight = document.createElement('button');
    this.buttonRight.addEventListener('mouseup', () =>
      this.stopScrollingRight()
    );
    this.buttonRight.addEventListener('mouseleave', () =>
      this.stopScrollingRight()
    );

    this.resize();
    window.addEventListener(
      'resize',
      throttle(() => this.resize(), 3000)
    );
  }

  currentScroll() {
    return this.root.scrollLeft;
  }

  scrollLeft({ step = 100, once = false } = {}) {
    const scroll = this.currentScroll();

    if (scroll <= 0) {
      this.loadPast();
    } else {
      const newScroll = scroll - step;
      this.scroll(newScroll);
      if (once || newScroll <= 0) return;

      this.keepScrollingLeft = true;
      setTimeout(() => {
        if (this.keepScrollingLeft) this.scrollLeft();
      }, 250);
    }
  }

  scrollRight() {
    this.scroll(this.currentScroll() + 100);

    this.keepScrollingRight = true;
    setTimeout(() => {
      if (this.keepScrollingRight) this.scrollRight();
    }, 250);
  }

  stopScrollingLeft() {
    this.keepScrollingLeft = false;
  }

  stopScrollingRight() {
    this.keepScrollingRight = false;
  }

  scrollToToday() {
    this.scroll(this.table.todayOffsetLeft());
  }

  scroll(left) {
    try {
      this.root.scroll({ left, top: 0, behavior: 'smooth' });
    } catch (_) {
      this.root.scrollLeft = left;
    }
  }

  resize() {
    const enabled = window.matchMedia(
      'only screen and (min-width: 948.001px)'
    ).matches;

    if (enabled && !this.enabled) {
      this.root.addEventListener('scroll', this.updateFn);
      this.buttonLeft.addEventListener('mousedown', this.scrollLeftFn);
      this.buttonRight.addEventListener('mousedown', this.scrollRightFn);
      this.observer.observe(this.root, {
        childList: true,
        subtree: true,
      });
      this.update();
      this.enabled = true;
    } else if (!enabled && this.enabled) {
      this.root.removeEventListener('scroll', this.updateFn);
      this.buttonLeft.removeEventListener('mousedown', this.scrollLeftFn);
      this.buttonRight.removeEventListener('mousedown', this.scrollRightFn);
      this.observer.disconnect();
      this.enabled = false;
    } else if (!enabled && this.enabled === undefined) {
      // initial update
      this.update();
    }
  }

  hideLeft(hide) {
    this.buttonLeft.classList.toggle(this.hiddenCss, hide);
  }

  hideRight(hide) {
    this.buttonRight.classList.toggle(this.hiddenCss, hide);
  }

  update() {
    if (
      (!this.buttonLeft.parentNode && !this.buttonRight.parentNode) ||
      this.updating
    )
      return;

    this.updating = true;
    requestAnimationFrame(() => {
      const { scrollLeft, offsetWidth, scrollWidth } = this.root;
      const scrollRight = scrollWidth - offsetWidth - scrollLeft;
      this.hideLeft(!this.enabled || (!this.hasPast && scrollLeft <= 0));
      this.hideRight(!this.enabled || scrollRight <= 0);
      this.updating = false;
    });
  }

  touchStarted(e) {
    if (!this.hasPast) return;

    this.dragStart = {
      x: e.touches[0].screenX,
      y: e.touches[0].screenY,
    };
    this.dragDirectionChecked = false;
    this.pulledToRefresh = false;
    this.buttonLeft.style.transition = 'none';
  }

  touchMoved(e) {
    if (!this.dragStart) return;
    if (this.currentScroll() > 0) return;

    const dx = e.touches[0].screenX - this.dragStart.x;
    if (dx <= 0) return;

    if (!this.dragDirectionChecked) {
      this.dragDirectionChecked = true;
      const dy = e.touches[0].screenY - this.dragStart.y;

      if (Math.abs(dx) < Math.abs(dy)) {
        this.dragStart = null;
        return;
      }
    }

    e.preventDefault();
    const w = Math.min(dx / 100.0, 1) * Math.min(dx, 60);
    this.pulledToRefresh = w > 40;
    this.buttonLeft.style.minWidth = `${w}px`;
    this.buttonLeft.classList.toggle(this.releaseCss, this.pulledToRefresh);
  }

  touchEnded() {
    if (!this.dragStart) return;

    this.dragStart = null;
    this.buttonLeft.classList.remove(this.releaseCss);
    const p = this.pulledToRefresh ? this.loadPast() : Promise.resolve();
    p.finally(() => this.finishDrag());
  }

  finishDrag() {
    this.buttonLeft.style.transition = 'min-width 0.3s';
    this.buttonLeft.style.minWidth = 0;
  }

  loadPast() {
    if (this.locked) {
      showSignup();
      return Promise.resolve();
    }

    if (!this.hasPast || this.loading) return Promise.resolve();

    this.loading = true;
    this.buttonLeft.classList.add(this.loadingCss);
    return this.table
      .back()
      .then(res => {
        this.hasPast = res.hasPast;

        if (!this.hasPast) {
          this.hideLeft(true);

          if (res.locked) {
            this.locked = true;
            this.leftIcons.forEach(icon => {
              // eslint-disable-next-line no-param-reassign
              icon.innerHTML = '<use xlink:href="#svg-icon-padlock"></use>';
            });

            showSignup();
          } else {
            this.leftLabels.forEach(label => {
              // eslint-disable-next-line no-param-reassign
              label.classList.add('hindcast-prompt__title--disabled');
            });
          }
        }
      })
      .finally(() => {
        this.loading = false;
        this.buttonLeft.classList.remove(this.loadingCss);
      });
  }
}
