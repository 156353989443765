import fetchCells from './fetch-cells';
import css from './styles';

export default class ForecastTableDay {
  cellsUrl(level) {}
  initCell(td, rowI) {}

  constructor(cells = []) {
    this.cells = cells;

    this.cells.filter(l => l).forEach(level =>
      level.forEach((row, i) =>
        row.forEach(td => this.initCell(td, i))
      )
    );
  }

  changeLevel(level) {
    if (this.cells[level]) {
      this.setLevel(level);
      return Promise.resolve();
    } else {
      const url = this.cellsUrl(level);

      return fetchCells(url).then(rows => {
        this.insertCells(rows, level);
        this.setLevel(level);
        return rows;
      });
    }
  }

  setLevel(level) {
    this.cells[this.level].forEach(row =>
      row.forEach(td => td.classList.add(css.hidden))
    );

    this.level = level;

    this.cells[this.level].forEach(row =>
      row.forEach(td => td.classList.remove(css.hidden))
    );
  }

  insertCells(rows, level) {
    rows.forEach((row, i) => {
      const cells = this.cells[this.level][i];
      if (!cells) return;

      const cell = cells[0];
      if (!cell) return;

      const parent = cell.parentNode;
      row.forEach(td => {
        this.initCell(td, i);
        parent.insertBefore(td, cell);
      });
    });

    this.cells[level] = rows;
  }
}
