export function tableSections(table, keyFn) {
  const cells = [];

  for (let rowI = 0; rowI < table.rows.length; rowI++) {
    const row = table.rows[rowI];
    const key = keyFn ? keyFn(row, rowI) : row.dataset.row || rowI;
    let sectionI = 0;
    let partI = 0;

    Array.from(row.cells).forEach(cell => {
      if (cell.tagName == 'TH') return;

      if (!cells[sectionI]) cells[sectionI] = {};
      if (!cells[sectionI][key]) cells[sectionI][key] = [];
      cells[sectionI][key].push(cell);

      partI += cell.colSpan;
      const { days } = cells[sectionI];
      if (days && days.length && partI >= days[0].colSpan) {
        partI = 0;
        sectionI++;
      }
    });
  }

  return cells;
}
