import select from 'd3-selection/src/select';
import scaleLinear from 'd3-scale/src/linear';
import area from 'd3-shape/src/area';
import range from 'd3-array/src/range';
import { monotoneX } from 'd3-shape/src/curve/monotone';
import { event } from 'd3-selection/src/selection/on';

import css from './styles';

import Units from 'utils/units';

const secondsPerDay = 24 * 3600;
const partsPerDay = 4;

function titleize(string) {
  return string.charAt(0).toUpperCase() + string.substr(1);
}

function formatHeight(h, precision) {
  const heightStr = Units.formatLength(h, { precision });
  const unitsStr = Units.formatLengthUnits();
  return `${heightStr}${unitsStr}`;
}

function dotTooltip(t, precision, hideType = false) {
  const labelCss = t.type === 'high' ? css.valueHigh : css.valueLow;
  const heightCss = t.type === 'high' ? css.heightHigh : css.heightLow;

  if (hideType) {
    const div = document.createElement('div');
    div.classList.add(`${css.tooltip}--fixed`);

    const time = document.createElement('div');
    time.classList.add(labelCss);
    time.textContent = t.time;
    div.appendChild(time);

    const height = document.createElement('div');
    height.classList.add(heightCss);
    const changer = () => {
      height.textContent = formatHeight(t.height, precision);
    };
    changer();
    Units.onChange(changer);
    div.appendChild(height);

    return div;
  } else {
    const height = formatHeight(t.height, precision);
    return `<div class="${css.tooltip}--fixed">` +
      (hideType ? '' : `<div class="${labelCss}">${titleize(t.type)}</div>`) +
      `<div class="${labelCss}">${t.time}</div>` +
      `<div class="${heightCss}">${height}</div>` +
      `</div>`;
  }
}

function liveTooltip(t, precision) {
  return `<div class="${css.liveLabel}">The Tide<br>is ${t.type}</div>` +
    `<div>Current height:</div>` +
    `<div>${formatHeight(t.height, precision)}</div>`;
}

function tooltipClass(suffix) {
  return `${css.tooltip} ${css.tooltip}--${suffix}`;
}

// type TideDot = {
//   x: number
//   y: number
//   day: TideTableDay
//   tide: Tide
// }

function Chart(
  chartNode,
  labelsNode,
  startTimestamp,
  days,
  firstTide,
  lastTide,
  minTideHeight,
  maxTideHeight,
  cellWidth,
  height,
  numYTicks,
  mode,
  dotRadius = 4,
  liveRadius = 4,
  tooltipWidth = 76
) {
  let numCols = null;
  let width = null;
  const touchRadius = dotRadius * 7;
  const precision = maxTideHeight / numYTicks < 0.1 ? 2 : 1;

  // add 5% margin
  const minHeight = minTideHeight / 0.95;
  const maxHeight = maxTideHeight / 0.95;

  const heightAt = d => minHeight + ((maxHeight - minHeight) * d) / numYTicks;

  const y = scaleLinear()
    .domain([minHeight, maxHeight])
    .range([height, 0]);
  const x = scaleLinear();

  // prepare data

  const tides = [];
  const tideDots = [];
  const nights = [];
  let sunset = null;
  let tideDotId = 1;

  nights.push([startTimestamp - 1, days[0].day.sunrise]);
  if (firstTide) tides.push(firstTide);

  days.forEach((day, i) => {
    const d = day.day;

    if (sunset && d.sunrise) nights.push([sunset, d.sunrise]);
    sunset = d.sunset;

    d.tides.forEach(tide => {
      tides.push(tide);
      if (tide.type) tideDots.push({ day, tide, id: tideDotId++ });
    });
  });

  nights.push([sunset, startTimestamp + days.length * secondsPerDay + 1]);
  if (lastTide) tides.push(lastTide);

  // create labels

  const labelsSel = select(labelsNode);
  const labelsWidth = labelsNode.offsetWidth;
  const showBothUnits = labelsNode.dataset.bothUnits;
  const labelsSvg = labelsSel
    .append('svg')
    .attr('class', css.chart)
    .attr('viewBox', [0, 0, labelsWidth, height]);

  // create chart

  const chartSel = select(chartNode);

  const svg = chartSel
    .append('svg')
    .attr('class', css.chart);

  const defs = svg.append('defs');

  // high dot def: use the same id as the class name

  defs
    .append('g')
    .attr('id', css.highDot)
    .append('circle')
    .attr('class', css.highDot)
    .attr('r', dotRadius)
    .attr('cx', 0)
    .attr('cy', 0);

  // low dot def: use the same id as the class name

  defs
    .append('g')
    .attr('id', css.lowDot)
    .append('circle')
    .attr('class', css.lowDot)
    .attr('r', dotRadius)
    .attr('cx', 0)
    .attr('cy', 0);

  const tooltip = select('body')
    .append('div')
    .attr('class', css.tooltip)
    .style('opacity', 0);

  const showTooltip = (content, cssClass, tx, ty) => {
    tooltip
      .attr('class', cssClass)
      .html(content)
      .style('left', `${tx}px`)
      .style('top', `${ty}px`)
      .transition()
      .duration(200)
      .style('opacity', 95);
  };

  const showDotTooltip = (t, px, py) => {
    const html = dotTooltip(t, precision);
    const cssClass = tooltipClass(t.type);
    showTooltip(html, cssClass, px - tooltipWidth / 2, py - 59);
  };

  const hideTooltip = () => {
    tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  };

  svg.on('touchend', () => {
    const svgRect = svg.node().getBoundingClientRect();
    const cx = event.pageX - svgRect.left - window.pageXOffset;
    const cy = event.pageY - svgRect.top - window.pageYOffset;

    const collapsedDayDots = tideDots.filter(dot => dot.day.level === 0);
    const dists = collapsedDayDots.map(dot => {
      const dx = dot.x - cx;
      const dy = dot.y - cy;
      return dx * dx + dy * dy;
    });
    const minDist = Math.min(...dists);

    if (minDist <= touchRadius * touchRadius) {
      const dot = collapsedDayDots[dists.indexOf(minDist)];
      showDotTooltip(dot.tide, event.pageX, event.pageY);
    } else {
      hideTooltip();
    }
  });

  const nightRectsG = svg.append('g');
  const dayRectsG = svg.append('g');
  const gridLinesYG = svg.append('g');
  const gridLinesXG = svg.append('g');

  const tideCurve = svg
    .append('path')
    .datum(tides)
    .attr('class', css.area);

  const tideDotsG = svg.append('g');
  const tooltipsDiv = chartSel.append('div').attr('class', css.tooltips);

  let liveTide = null;
  let liveDot = null;
  let liveLineX = null;
  let liveLineY = null;

  const createLive = () => {
    liveDot = svg
      .append('g')
      .on('mouseover', () => {
        const html = liveTooltip(liveTide, precision);
        const cssClass = tooltipClass('live');
        showTooltip(html, cssClass, event.pageX + 5, event.pageY - 35);
      })
      .on('mouseout', hideTooltip);

    const c = liveDot
      .append('circle')
      .attr('class', css.liveDot)
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', liveRadius);

    if (!document.body.classList.contains('no-animations')) {
      c.append('animate')
        .attr('attributeName', 'r')
        .attr(
          'values',
          `${liveRadius}; ${liveRadius}; 0; ${liveRadius}; ${liveRadius}`
        )
        .attr('keyTimes', `0; 0.4; 0.5; 0.6; 1`)
        .attr('dur', '1.5s')
        .attr('repeatCount', 'indefinite');
    }

    liveLineX = svg.append('line').attr('class', css.liveLine);
    liveLineY = svg.append('line').attr('class', css.liveLine);
  };

  const renderLive = () => {
    if (!liveTide) return;

    const liveX = x(liveTide.timestamp);
    const liveY = y(liveTide.height);

    liveDot.attr('transform', `translate(${liveX} ${liveY})`);

    liveLineX
      .attr('x1', 0)
      .attr('x2', liveX)
      .attr('y1', liveY)
      .attr('y2', liveY);

    liveLineY
      .attr('x1', liveX)
      .attr('x2', liveX)
      .attr('y1', 0)
      .attr('y2', height);
  };

  const live = tide => {
    if (!liveDot) createLive();
    liveTide = tide;
    renderLive();
  };

  const render = days => {
    const times = [startTimestamp];
    const offsets = [0];
    numCols = days.length;
    let { level } = days[0];
    let time = startTimestamp;
    let offset = 0;

    days.forEach(day => {
      if (day.level === 1) {
        numCols += partsPerDay - 1;
      }

      if (day.level !== level) {
        times.push(time);
        offsets.push(offset);
        ({ level } = day);
      }

      time += secondsPerDay;
      offset += cellWidth * (day.level === 1 ? partsPerDay : 1);
    });
    times.push(time);
    offsets.push(offset);

    width = numCols * cellWidth;
    svg.attr('viewBox', [0, 0, width, height]);
    x.domain(times).range(offsets);

    const nightRects = nightRectsG.selectAll('rect').data(nights);

    nightRects
      .enter()
      .append('rect')
      .attr('class', css.night)
      .merge(nightRects)
      .attr('x', d => x(d[0]))
      .attr('y', 0)
      .attr('width', d => x(d[1]) - x(d[0]))
      .attr('height', height);

    nightRects.exit().remove();

    const dayRects = dayRectsG.selectAll('rect').data(nights.slice(0, -1));

    dayRects
      .enter()
      .append('rect')
      .attr('class', css.day)
      .merge(dayRects)
      .attr('x', (_, i) => x(nights[i][1]))
      .attr('y', 0)
      .attr('width', (_, i) => x(nights[i + 1][0]) - x(nights[i][1]))
      .attr('height', height);

    dayRects.exit().remove();

    const gridLinesX = gridLinesXG
      .selectAll('line')
      .data(range(1, days.length * partsPerDay));

    gridLinesX
      .enter()
      .append('line')
      .attr('class', d => (d % partsPerDay === 0 ? css.blueLine : css.greyLine))
      .merge(gridLinesX)
      .attr('x1', d => x(startTimestamp + (d * secondsPerDay) / partsPerDay))
      .attr('x2', d => x(startTimestamp + (d * secondsPerDay) / partsPerDay))
      .attr('y1', 0)
      .attr('y2', height);

    gridLinesX.exit().remove();

    const gridLinesY = gridLinesYG
      .selectAll('line')
      .data(range(0, numYTicks + 1));

    gridLinesY
      .enter()
      .append('line')
      .attr('class', css.greyLine)
      .merge(gridLinesY)
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', d => y(heightAt(d)))
      .attr('y2', d => y(heightAt(d)));

    gridLinesY.exit().remove();

    tideCurve.attr(
      'd',
      area()
        .x(t => x(t.timestamp))
        .y0(y(minHeight))
        .y1(t => y(t.height))
        .curve(monotoneX)
    );

    tideDots.forEach(dot => {
      dot.x = x(dot.tide.timestamp);
      dot.y = y(dot.tide.height);
    });

    const tideDotsS = tideDotsG.selectAll('use').data(tideDots);

    tideDotsS
      .enter()
      .append('use')
      .attr('xlink:href', dot => `#${css[`${dot.tide.type}Dot`]}`)
      .merge(tideDotsS)
      .attr('x', dot => dot.x)
      .attr('y', dot => dot.y)
      .on('mouseover', dot => {
        if (dot.day.level !== 0) return;
        showDotTooltip(dot.tide, event.pageX, event.pageY);
      })
      .on('mouseout', hideTooltip);

    tideDotsS.exit().remove();

    if (mode === 'live') {
      const tooltips = tooltipsDiv
        .selectAll(`.${css.tooltip}`)
        .data(tideDots.filter(dot => dot.day.level === 1), d => d.id)
        .join(
          enter => {
            const div = enter
              .append('div')
              .attr('class', dot => tooltipClass(dot.tide.type));
            div.append(dot => dotTooltip(dot.tide, precision, true));
            return div;
          },
          update => update,
          exit => exit.remove()
        )
        .style('left', dot => `${dot.x - tooltipWidth / 2}px`)
        .style('top', dot => `${dot.y + (dot.tide.type === 'low' ? 5 : -(5 + 38))}px`);

      renderLive();
    }

    return width;
  };

  const renderLabels = () => {
    const labels = labelsSvg.selectAll('text').data(range(0, numYTicks + 1));
    labels.exit().remove();
    labels
      .enter()
      .append('text')
      .merge(labels)
      .text(d => {
        const h = heightAt(d);

        const mainHeightStr = Units.formatLength(h, { precision });
        const mainUnitsStr = Units.formatLengthUnits();
        const main = `${mainHeightStr}${mainUnitsStr}`;
        if (!showBothUnits) return main;

        const altUnits = true;
        const altHeightStr = Units.formatLength(h, { precision, altUnits });
        const altUnitsStr = Units.formatLengthUnits({ altUnits });
        const alt = `${altHeightStr}${altUnitsStr}`;
        return `${main} (${alt})`;
      })
      .attr('x', labelsWidth - 2)
      .attr('y', d => y(heightAt(d)))
      .attr('text-anchor', 'end');
  };

  renderLabels();
  render(days);

  Units.onChange(renderLabels);

  return { hideTooltip, render, live };
}

export default Chart;
