const Menu = () => {
  const el = document.createElement('div');
  el.className = 'fullscreen-menu';

  let handlers = [];
  let timer;

  const api = {
    get() {
      return el;
    },
    append(parent) {
      parent.appendChild(el);

      return api;
    },
    remove() {
      handlers.forEach(x => el.removeEventListener(x.type, x.cb));
      handlers = [];

      el.parentNode.removeChild(el);

      return api;
    },
    add(newElement) {
      el.appendChild(newElement);

      return api;
    },
    setHandler(type, cb) {
      handlers.push({ type, cb });
      el.addEventListener(type, cb);

      return api;
    },
    hide() {
      if (timer) clearTimeout(timer);
      el.classList.add('is-moved');

      return api;
    },
    show() {
      if (timer) clearTimeout(timer);
      el.classList.remove('is-moved');

      timer = setTimeout(() => {
        el.classList.add('is-moved');
      }, 3000);

      return api;
    },
  };

  return Object.freeze(api);
};

export default Menu;
