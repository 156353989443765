import './index.scss';
import TideImage from './image';

export default function () {
  const versionSwitch = document.querySelector(
    '.tide-table-header__version-switch input'
  );
  if (versionSwitch) {
    const switchVersion = () => {
      const documentWidth = document.documentElement.clientWidth;

      fetch('/settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tide_table: versionSwitch.checked ? 'new' : 'old',
          url: window.location.href,
          screen_width: Math.max(documentWidth, window.innerWidth || 0),
        }),
      })
        .then(res => res.json())
        .then(json => {
          window.location.href = json.url;
        })
        .catch(() => {
          versionSwitch.disabled = false;
        });
    };

    versionSwitch.addEventListener('change', () => {
      versionSwitch.disabled = true;
      setTimeout(switchVersion, 100); // to give UI some time to update
    });
  }

  const printSwitch = document.querySelector(
    '.tide-table-header__print-switch input'
  );
  if (printSwitch) {
    printSwitch.addEventListener('change', () => {
      document.body.classList.toggle('tides-print-30');
    });
  }

  TideImage();
}
