import {
  CLASS_NAME_LENGTH,
  CLASS_NAME_TEMPERATURE,
  CLASS_NAME_SPEED_KPH,
  CLASS_NAME_SPEED_MPH,
  CLASS_NAME_SPEED_KNOTS,
} from './selector';

function update(container, units) {
  const lengthSwitcher = container.querySelector(`.${CLASS_NAME_LENGTH}`);
  lengthSwitcher.checked = units.length === 'Imperial';

  const temperatureSwitcher = container.querySelector(
    `.${CLASS_NAME_TEMPERATURE}`
  );
  temperatureSwitcher.checked = units.temperature === 'Imperial';

  const speedSwitcherKph = container.querySelector(`.${CLASS_NAME_SPEED_KPH}`);
  speedSwitcherKph.checked = units.speed === 'Metric';

  const speedSwitcherMph = container.querySelector(`.${CLASS_NAME_SPEED_MPH}`);
  speedSwitcherMph.checked = units.speed === 'Imperial';

  const speedSwitcherKnots = container.querySelector(`.${CLASS_NAME_SPEED_KNOTS}`);
  if (speedSwitcherKnots) speedSwitcherKnots.checked = units.speed === 'Knot';
}

export default update;
