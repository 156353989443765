function hasScrollSpaceOnRight({ offsetWidth, scrollWidth, scrollLeft }) {
  return scrollWidth - offsetWidth - scrollLeft !== 0;
}

function hasScrollSpaceOnLeft({ scrollLeft }) {
  return scrollLeft !== 0;
}

export default Object.freeze({
  left: hasScrollSpaceOnLeft,
  right: hasScrollSpaceOnRight,
});
