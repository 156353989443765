import {
  CLASS_NAME_LENGTH,
  CLASS_NAME_TEMPERATURE,
  CLASS_NAME_SPEED_KPH,
  CLASS_NAME_SPEED_MPH,
  CLASS_NAME_SPEED_KNOTS,
} from './selector';

import Units from 'utils/units';

function convert(newProp) {
  Units.change(newProp);
}

export default function(container) {
  [...container.querySelectorAll(`.${CLASS_NAME_LENGTH}`)].forEach(switcher => {
    switcher.addEventListener('change', event => {
      const { checked } = event.target;

      if (checked) convert({ length: 'Imperial' });
      else convert({ length: 'Metric' });
    });
  });

  [...container.querySelectorAll(`.${CLASS_NAME_TEMPERATURE}`)].forEach(
    switcher => {
      switcher.addEventListener('change', event => {
        const { checked } = event.target;

        if (checked) convert({ temperature: 'Imperial' });
        else convert({ temperature: 'Metric' });
      });
    }
  );

  [
    ...container.querySelectorAll(`.${CLASS_NAME_SPEED_KPH}`),
    ...container.querySelectorAll(`.${CLASS_NAME_SPEED_MPH}`),
    ...container.querySelectorAll(`.${CLASS_NAME_SPEED_KNOTS}`),
  ].forEach(switcher => {
    switcher.addEventListener('change', event => {
      const { value } = event.target;
      convert({ speed: value });
    });
  });
}
