import { WindIcon } from 'components/wind-icon';
import { SwellIcon } from 'components/swell-icon';

export default class LiveConditions {
  constructor({ el, clock, units, updateTimeout = 60000 }) {
    this.el = el;
    this.clock = clock;
    this.units = units;
    this.updateTimeout = updateTimeout;
    this.periods = JSON.parse(el.dataset.periods);
    this.firstPeriodStart = parseInt(el.dataset.firstPeriodStart, 10);
    this.periodDuration = parseInt(el.dataset.periodDuration, 10);
    this.timeout = null;

    const swellIconEl = el.querySelector('.swell-icon');
    this.swellIcon = new SwellIcon({ el: swellIconEl, units });

    const windIconEl = el.querySelector('.wind-icon');
    this.windIcon = new WindIcon(windIconEl, {});
  }

  show() {
    this.el.classList.remove('live-conditions--invisible');
    this.scheduleUpdate();
    this.render();
  }

  hide() {
    this.el.classList.add('live-conditions--invisible');
    this.stopUpdate();
  }

  render() {
    const period = this.currentPeriod();
    if (!period) return;

    this.renderSwell(period);
    this.renderWindSpeed(period);
    this.renderWeather(period);
  }

  renderSwell(period) {
    const dir = this.el.querySelector('.live-conditions__swell-direction');
    dir.innerHTML = `(${period.swell_direction})`;

    const units = this.el.querySelector('.live-conditions__swell-units');
    units.innerText = `(${this.units.formatLengthUnits()})`;

    let periodValue = period.swell_period;
    if (periodValue === 'n/a') periodValue = '-';

    this.swellIcon.update({
      height: period.swell_height,
      angle: period.swell_angle,
      period: periodValue,
    });
  }

  renderWindSpeed(period) {
    const speedUnits = this.el.querySelector('.live-conditions__speed-units');
    speedUnits.textContent = `(${this.units.formatSpeedUnits()})`;

    this.windIcon.update({
      speed: period.wind_speed,
      angle: period.wind_angle,
    });
  }

  renderWeather(period) {
    const weather = this.el.querySelector('.live-conditions__weather');
    weather.innerHTML = period.weather_icon;

    const summary = this.el.querySelector('.live-conditions__weather-summary');
    summary.innerHTML = `(${period.weather_summary})`;
  }

  scheduleUpdate() {
    this.render();
    this.timeout = setTimeout(() => this.scheduleUpdate(), this.updateTimeout);
  }

  stopUpdate() {
    if (!this.timeout) {
      return;
    }

    clearTimeout(this.timeout);
    this.timeout = null;
  }

  currentPeriod() {
    const currentTimestamp = this.clock.currentTimestamp();
    const index = Math.round(
      (currentTimestamp - this.firstPeriodStart) / this.periodDuration
    );

    if (index >= this.periods.length) return false;

    return this.periods[index];
  }
}
