import screenfull from 'screenfull';

import emitter from 'components/emitter';
import { debounce } from 'components/utils';
import tides from 'utils/tide/tides';

export default function init() {
  const tideImage = document.querySelector('.tides-content-image--js');

  if (!tideImage) {
    return false;
  }

  const dot = tideImage.querySelector('.dot');
  const image = tideImage.querySelector('img');

  const scale = image.offsetWidth / 793;

  let offsetX = 3; // diameter is 7
  let offsetY = 3;

  offsetX += 3; // correction is for 3 pixels to the left
  offsetY += 4; // correction is for 4 pixels up

  const update = () => {
    const [x, y] = tides.currentImagePos();

    const { style } = dot;
    style.left = `${scale * x - offsetX}px`;
    style.top = `${scale * y - offsetY}px`;
    style.visibility = 'inherit';
  };

  setInterval(update, 1000);

  window.addEventListener('resize', debounce(update, 50));

  const updateNextFrame = () => setTimeout(update, 0);
  window.addEventListener('orientationchange', updateNextFrame);
  emitter.on('tides-content:changed', updateNextFrame);
  if (screenfull.enabled) screenfull.on('change', updateNextFrame);

  return true;
}
