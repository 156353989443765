import css from './styles';

function StickyNav() {
  const container = document.querySelector(`.${css.container}`);
  const stickyNav = document.querySelector('.top-tabs--js');
  if (!stickyNav) return;
  const stickyHeight = stickyNav.offsetHeight / 2;
  const viewportHeight = window.innerHeight;

  window.addEventListener('scroll', () => {
    const windowOffset = window.pageYOffset;
    const noStickyStart = container.offsetTop - stickyHeight;
    const noStickyStop =
      container.offsetTop +
      container.offsetHeight +
      stickyHeight -
      viewportHeight;

    if (windowOffset >= noStickyStart && windowOffset <= noStickyStop) {
      stickyNav.style.display = 'none';
    } else {
      stickyNav.style.display = 'block';
    }
  });
}

export default StickyNav;
