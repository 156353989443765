class Clock {
  constructor(serverTime, timezoneOffsetHr) {
    if (serverTime) {
      this.correction = serverTime - Date.now() / 1000;
    } else {
      this.correction = 0;
    }

    this.timezoneOffsetHr = timezoneOffsetHr;
  }

  currentTimestamp() {
    return Date.now() / 1000 + this.correction;
  }

  timestampToLocal(timestamp) {
    const offset = this.timezoneOffsetHr + new Date().getTimezoneOffset() / 60;
    return new Date(timestamp * 1000 + 3600000 * offset);
  }
}

let clock;

if (window.FCGON) {
  const { serverTime, hrdiff } = window.FCGON;
  clock = new Clock(serverTime, hrdiff);
} else {
  clock = new Clock(null, 0);
}

export default clock;
