export default class Arrow {
  constructor({ el, tides }) {
    this.el = el;
    this.img = this.el.querySelector('img');
    this.tides = tides;
  }

  update(timestamp) {
    let deg = 0;

    const { prev, next } = this.tides.nextAndPrevTides();

    if (!prev || !next) {
      return;
    }

    if (next.type === 'high') {
      deg += 180;
    }

    deg +=
      (180 * (timestamp - prev.timestamp)) / (next.timestamp - prev.timestamp);

    const style = `rotate(${deg}deg)`;
    this.img.style.webkitTransform = style;
    this.img.style.MozTransform = style;
    this.img.style.msTransform = style;
    this.img.style.OTransform = style;
    this.img.style.transform = style;
  }
}
