import units from '../../utils/units'
import { BaseComponent } from '../../utils/base-component'

export class LevelValue extends BaseComponent {
  static cssClass = 'level-value'

  value?: number

  init() {
    const value = parseFloat(this.root.dataset.value!)
    this.value = Number.isNaN(value) ? undefined : value
    this.render()
    units.onChange(() => this.render())
  }

  render() {
    this.root.textContent = this.value ? units.formatLevel(this.value) : '—'
  }
}
